import React from 'react';
import { Table, UncontrolledTooltip } from 'reactstrap';

import { AuctionDetails, Bidders } from 'models/auctionDetails';
import useDashboardTranslation from 'hooks/useDashboardTranslation';
import { formatInteger, defaultTo0 } from 'utils/number';

import useActiveBiddersCount from './hooks/useActiveBiddersCount';

type BiddersStats = Bidders & { active: number };

const createTotalsRow = (totalHammerStats: BiddersStats[], label: string) => {
  return totalHammerStats.reduce((acc, item) => ({
    platform: label,
    registered: acc.registered + item.registered,
    approved: acc.approved + item.approved,
    pending: acc.pending + item.pending,
    active: defaultTo0(acc.active) + defaultTo0(item.active),
  }));
};

interface BiddersSummaryProps {
  auctionDetails: AuctionDetails;
}

const BiddersSummary: React.FC<BiddersSummaryProps> = ({ auctionDetails }) => {
  const { t } = useDashboardTranslation();
  const { data: activeBidders } = useActiveBiddersCount(auctionDetails.ref);

  const mappedBidders: BiddersStats[] = auctionDetails.bidders.map(x => ({
    ...x,
    active: activeBidders?.find(y => y.platform === x.platform)?.active ?? 0,
  }));
  const isListedOnMultiplePlatforms = mappedBidders.length > 1;
  const rows = isListedOnMultiplePlatforms
    ? [...mappedBidders, createTotalsRow(mappedBidders, t('auctionDetails.totals'))]
    : mappedBidders;

  return (
    <>
      <Table striped responsive size="sm" className="atg-table">
        <thead>
          <tr>
            {isListedOnMultiplePlatforms && <th>{t('auctionDetails.platform')}</th>}
            <th>{t('auctionDetails.registeredBidders')}</th>
            <th>{t('auctionDetails.approvedBidders')}</th>
            <th>{t('auctionDetails.pendingBidders')}</th>
            <th>
              {t('auctionDetails.activeBidders')} <span className="icon-info" id="active-bidders-info" />
              <UncontrolledTooltip target="active-bidders-info">
                {t('auctionDetails.activeBiddersInfo', { count: auctionDetails.activeBiddersTimespan })}
              </UncontrolledTooltip>
            </th>
          </tr>
        </thead>
        <tbody>
          {rows.map(item => (
            <tr key={item.platform} data-testid={item.platform}>
              {isListedOnMultiplePlatforms && <td>{item.platform}</td>}
              <td data-testid="registered">{formatInteger(item.registered)}</td>
              <td data-testid="approved">{formatInteger(item.approved)}</td>
              <td data-testid="pending">{formatInteger(item.pending)}</td>
              <td data-testid="active">{formatInteger(item.active)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default BiddersSummary;
