import React, { CSSProperties } from 'react';
import { animated, AnimatedProps } from 'react-spring';

import { Lot } from 'models/lot';
import { formatCurrencyWithoutSymbol, formatInteger } from 'utils/number';
import useDashboardTranslation from 'hooks/useDashboardTranslation';
import { UncontrolledTooltip } from 'reactstrap';

interface HammerPriceProps {
  lot: Lot;
  multipleItems: boolean;
  timeFromLastBid?: string;
  showReserve?: boolean;
  spring: AnimatedProps<CSSProperties>;
}

const HammerPrice: React.FC<HammerPriceProps> = ({ lot, multipleItems, timeFromLastBid, showReserve, spring }) => {
  const { t } = useDashboardTranslation();

  if (!lot.hammerPrice) return <>-</>;

  const reserveMet = !!lot.reservePrice && lot.hammerPrice >= lot.reservePrice;
  const reserveNotMet = !!lot.reservePrice && lot.hammerPrice < lot.reservePrice;

  return (
    <>
      <div className="d-flex align-items-center flex-wrap">
        {lot.isHammerLoaded && (
          <>
            <span className="icon-warning mr-2" id="hammer-loaded" />
            <UncontrolledTooltip target="hammer-loaded">{t('lotList.hammerLoadedTooltip')}</UncontrolledTooltip>
          </>
        )}
        <span className="mr-2">{formatCurrencyWithoutSymbol(lot.hammerPrice)}</span>

        <animated.div className="change-label" style={{ opacity: spring.opacity }}>
          {t('lotList.newBid')}
        </animated.div>
      </div>

      {multipleItems && lot.totalHammerPrice && (
        <div className="text-tight">
          <small>
            {t('lotList.multiplicationEquation', {
              multiplicand: formatCurrencyWithoutSymbol(lot.hammerPrice),
              multiplier: formatInteger(lot.quantity),
              product: formatCurrencyWithoutSymbol(lot.totalHammerPrice),
            })}
          </small>
        </div>
      )}

      {timeFromLastBid && (
        <div className="text-tight">
          <small>{timeFromLastBid}</small>
        </div>
      )}

      {showReserve && (
        <>
          {reserveMet && (
            <div className="reserve reserve-met">
              <span className="icon-check-circle mr-1" /> <small>{t('lotList.reserveMet')}</small>
            </div>
          )}
          {reserveNotMet && (
            <div className="reserve reserve-not-met">
              <span className="icon-arrow-circle-o-down mr-1" />{' '}
              <small>{t('lotList.reserveEquals', { value: formatCurrencyWithoutSymbol(lot.reservePrice!) })}</small>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default HammerPrice;
