import React from 'react';
import { Table } from 'reactstrap';

import { AuctionDetails } from 'models/auctionDetails';
import useDashboardTranslation from 'hooks/useDashboardTranslation';

import { formatInteger } from 'utils/number';
import ReserveBreakdown from './ReserveBreakdown';

interface LotsSummaryProps {
  auctionDetails: AuctionDetails;
}

const LotsSummary: React.FC<LotsSummaryProps> = ({ auctionDetails }) => {
  const { t } = useDashboardTranslation();

  const showTotalNumberOfBids = auctionDetails.lots.some(x => x.totalNumberOfBids != null);

  return (
    <Table striped responsive size="sm" className="atg-table">
      <thead>
        <tr>
          <th>{t('auctionDetails.lotsInSale')}</th>
          <th>{t('auctionDetails.activeLots')}</th>
          <th>{t('auctionDetails.closedLots')}</th>
          {showTotalNumberOfBids && <th>{t('auctionDetails.totalBids')}</th>}
        </tr>
      </thead>
      <tbody>
        {auctionDetails.lots.map(item => (
          <tr key={item.platform} data-testid={item.platform}>
            <td data-testid="total">
              {auctionDetails.showReserves ? (
                <ReserveBreakdown
                  value={formatInteger(item.total)}
                  metValue={formatInteger(item.totalReserveMet)}
                  notMetValue={formatInteger(item.totalReserveNotMet)}
                />
              ) : (
                formatInteger(item.total)
              )}
            </td>
            <td data-testid="active">
              {auctionDetails.showReserves ? (
                <ReserveBreakdown
                  value={formatInteger(item.active)}
                  metValue={formatInteger(item.activeReserveMet)}
                  notMetValue={formatInteger(item.activeReserveNotMet)}
                />
              ) : (
                formatInteger(item.active)
              )}
            </td>
            <td data-testid="closed">
              {auctionDetails.showReserves ? (
                <ReserveBreakdown
                  value={formatInteger(item.closed)}
                  metValue={formatInteger(item.closedReserveMet)}
                  notMetValue={formatInteger(item.closedReserveNotMet)}
                />
              ) : (
                formatInteger(item.closed)
              )}
            </td>
            {showTotalNumberOfBids && (
              <td data-testid="total-bids">
                {item.totalNumberOfBids != null && formatInteger(item.totalNumberOfBids)}
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default LotsSummary;
