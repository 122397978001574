import React, { CSSProperties } from 'react';
import { animated, AnimatedProps } from 'react-spring';

import useDashboardTranslation from 'hooks/useDashboardTranslation';
import InternalLink from 'components/InternalLink/InternalLink';

interface LeadingBidderProps {
  leadingBidderName?: string;
  spring: AnimatedProps<CSSProperties>;
  onBidderNameClick: () => void;
}

const LeadingBidder: React.FC<LeadingBidderProps> = ({ leadingBidderName, spring, onBidderNameClick }) => {
  const { t } = useDashboardTranslation();

  if (!leadingBidderName) return <>-</>;

  return (
    <>
      <InternalLink onClick={onBidderNameClick}>
        <span className="text-underline">{leadingBidderName}</span>
      </InternalLink>

      <animated.span className="change-label" style={{ opacity: spring.opacity }}>
        {t('lotList.maxBidUpdated')}
      </animated.span>
    </>
  );
};

export default LeadingBidder;
