import { useState, useEffect } from 'react';

import { fetchCurrentTime } from 'api';
import { changeMomentTimeSource } from 'utils/date';

const useServerTime = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchCurrentTime()
      .then(res => changeMomentTimeSource(res.data))
      .finally(() => setIsLoading(false));
  }, []);

  return { isLoading };
};

export default useServerTime;
