import React from 'react';
import classNames from 'classnames';

import './InternalLink.scss';

interface InternalLinkProps {
  className?: string;
  title?: string;
  testId?: string;
  onClick: () => void;
}

const InternalLink: React.FC<InternalLinkProps> = ({ children, className, title, testId, onClick }) => {
  return (
    <button
      type="button"
      className={classNames('internal-link', className)}
      title={title}
      data-testid={testId}
      onClick={() => onClick()}
    >
      {children}
    </button>
  );
};

export default InternalLink;
