import { useRef, useEffect } from 'react';

const usePreventRowAnimation = (lotsTimestamp: number) => {
  const preventRowAnimationsRef = useRef(true);

  useEffect(() => {
    if (!lotsTimestamp) return;

    preventRowAnimationsRef.current = false;
  }, [lotsTimestamp]);

  const withoutRowAnimations = (fn: () => void) => {
    preventRowAnimationsRef.current = true;
    fn();
  };

  return { preventRowAnimations: preventRowAnimationsRef.current, withoutRowAnimations };
};

export default usePreventRowAnimation;
