import React from 'react';
import * as R from 'ramda';
import classNames from 'classnames';

import { useAppContext } from 'contexts/AppContextProvider';
import { formatCurrencyWithoutSymbol, formatCurrency, hasDecimals } from 'utils/number';
import { IncrementDetails } from 'models/auctionDetails';

interface AuctionIncrementLineProps {
  increment: IncrementDetails;
  currentAuctionIncrement?: IncrementDetails;
}

const AuctionIncrementLine: React.FC<AuctionIncrementLineProps> = ({ increment, currentAuctionIncrement }) => {
  const { auctionDetails } = useAppContext();
  const showZeros =
    hasDecimals(increment.fromAmount) || hasDecimals(increment.toAmount) || hasDecimals(increment.incrementValue);
  const isCurrent = R.equals(increment, currentAuctionIncrement);
  const fromAmount = formatCurrencyWithoutSymbol(increment.fromAmount, showZeros);
  const toAmount = increment.toAmount ? formatCurrencyWithoutSymbol(increment.toAmount, showZeros) : undefined;
  const incrementValue = formatCurrency(auctionDetails.currencyCode)(increment.incrementValue, showZeros);

  return (
    <tr className={classNames({ 'font-weight-semi-bold': isCurrent })}>
      <td className="text-right">{fromAmount}</td>
      <td className="text-center">{toAmount ? '-' : '+'}</td>
      <td className="text-right">{toAmount}</td>
      <td>=</td>
      <td className="text-right">{incrementValue}</td>
    </tr>
  );
};

export default AuctionIncrementLine;
