import React from 'react';
import { Table } from 'reactstrap';

import './PendingBiddersModal.scss';

import useDashboardTranslation from 'hooks/useDashboardTranslation';
import { PendingBidder } from 'models/pendingBidder';

import PendingBiddersTableRow from './PendingBiddersTableRow';

interface PendingBiddersTableProps {
  bidders: PendingBidder[];
  activeBidderId: string | undefined;
  onBidderDetailsClick: (bidderId: string) => void;
}

const PendingBiddersTable: React.FC<PendingBiddersTableProps> = ({ bidders, activeBidderId, onBidderDetailsClick }) => {
  const { t } = useDashboardTranslation();

  return (
    <Table size="sm" striped className="atg-table">
      <thead>
        <tr>
          <th>{t('auctionDetails.pendingBiddersModal.paddle')}</th>
          <th>{t('auctionDetails.pendingBiddersModal.customerName')}</th>
          <th>{t('auctionDetails.pendingBiddersModal.companyName')}</th>
          <th>{t('auctionDetails.pendingBiddersModal.status')}</th>
          <th> </th>
        </tr>
      </thead>
      <tbody>
        {bidders.map(bidder => (
          <PendingBiddersTableRow
            key={bidder.bidderId}
            bidder={bidder}
            isActive={bidder.bidderId === activeBidderId}
            onBidderDetailsClick={() => onBidderDetailsClick(bidder.bidderId)}
          />
        ))}
      </tbody>
    </Table>
  );
};

export default PendingBiddersTable;
