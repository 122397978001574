import moment from 'moment-timezone';

export const changeMomentTimeSource = (epoch: number) => {
  const offset = epoch - Date.now();
  moment.now = () => Date.now() + offset;
};

export const formatDate = (date: string | Date): string => {
  const format = 'D MMM YYYY';
  return moment.utc(date).format(format);
};

export const formatDateWithTimezone = (date: string | Date, timezone: string): string => {
  const format = 'D MMM h.mm a z';
  return moment
    .utc(date)
    .tz(timezone)
    ?.format(format);
};
