import React from 'react';

import useDashboardTranslation from 'hooks/useDashboardTranslation';
import { formatCurrency, formatInteger } from 'utils/number';
import { formatDate } from 'utils/date';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';

import useBidderHistory from '../hooks/useBidderHistory';

interface BidderInfoHistoryTabProps {
  customerId: string;
}

const BidderInfoHistoryTab: React.FC<BidderInfoHistoryTabProps> = ({ customerId }) => {
  const { t } = useDashboardTranslation();
  const { bidderHistory, isLoading, errorMessage } = useBidderHistory(customerId);

  if (isLoading) return <LoadingIndicator />;
  if (!bidderHistory) return <p>{errorMessage}</p>;

  const asCurrency = formatCurrency(bidderHistory.currency);

  return (
    <dl>
      <dt>{t('bidderInfo.totalSpent')}</dt>
      <dd>{asCurrency(bidderHistory.totalSpent)}</dd>
      <dt>{t('bidderInfo.totalLotsWon')}</dt>
      <dd>{formatInteger(bidderHistory.totalLotsWon)}</dd>
      <dt>{t('bidderInfo.lotsCancelled')}</dt>
      <dd>{asCurrency(bidderHistory.lotsCancelled)}</dd>
      <dt>{t('bidderInfo.totalClientRegistrations')}</dt>
      <dd>{formatInteger(bidderHistory.totalClientRegistrations)}</dd>
      <dt>{t('bidderInfo.totalRegistrations')}</dt>
      <dd>{formatInteger(bidderHistory.totalOthersRegistrations)}</dd>
      <dt>{t('bidderInfo.firstRegistrationDate')}</dt>
      <dd>{formatDate(bidderHistory.registrationDateUtc)}</dd>
      <dt>{t('bidderInfo.positiveCommentsCount')}</dt>
      <dd>{formatInteger(bidderHistory.positiveComments)}</dd>
      <dt>{t('bidderInfo.negativeCommentsCount')}</dt>
      <dd>{formatInteger(bidderHistory.negativeComments)}</dd>
    </dl>
  );
};

export default BidderInfoHistoryTab;
