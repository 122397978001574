import * as R from 'ramda';

export const defaultTo0 = R.defaultTo(0);

export const hasDecimals = (value?: number) => defaultTo0(value) % 1 > 0;

export const formatCurrency = (currency: string) => (value: number, showZeros = true) =>
  new Intl.NumberFormat(window.navigator.language, {
    style: 'currency',
    currency,
    minimumFractionDigits: showZeros ? 2 : 0,
  }).format(defaultTo0(value));

export const formatCurrencyWithoutSymbol = (value: number, showZeros = true) =>
  new Intl.NumberFormat(window.navigator.language, {
    minimumFractionDigits: showZeros ? 2 : 0,
    maximumFractionDigits: 2,
  }).format(defaultTo0(value));

const integerFormatter = new Intl.NumberFormat(window.navigator.language, { maximumFractionDigits: 0 });

export const formatInteger = (value: number) => integerFormatter.format(defaultTo0(value));
