import React from 'react';

import './ReserveBreakdown.scss';

interface ReserveBreakdownProps {
  value: string | number;
  metValue: string | number;
  notMetValue: string | number;
}

const ReserveBreakdown: React.FC<ReserveBreakdownProps> = ({ value, metValue, notMetValue }) => {
  return (
    <div className="reserve-breakdown">
      <div className="reserve-breakdown-item">{value}</div>
      <div className="reserve-breakdown-item">
        <small className="reserve-price">
          <span className="icon-check-circle" /> {metValue}
        </small>
      </div>
      <div className="reserve-breakdown-item">
        <small className="reserve-price">
          <span className="icon-arrow-circle-o-down" /> {notMetValue}
        </small>
      </div>
    </div>
  );
};

export default ReserveBreakdown;
