import React, { CSSProperties } from 'react';
import { animated, AnimatedProps } from 'react-spring';

import { Lot } from 'models/lot';
import { formatCurrencyWithoutSymbol, formatInteger } from 'utils/number';
import useDashboardTranslation from 'hooks/useDashboardTranslation';
import InternalLink from 'components/InternalLink/InternalLink';

interface MaxBidProps {
  lot: Lot;
  multipleItems: boolean;
  spring: AnimatedProps<CSSProperties>;
  onBidderNameClick: () => void;
}

const MaxBid: React.FC<MaxBidProps> = ({ lot, multipleItems, spring, onBidderNameClick }) => {
  const { t } = useDashboardTranslation();

  if (!lot.maxBid) return <>-</>;

  return (
    <>
      <div className="d-flex align-items-center flex-wrap">
        <span className="mr-2">{formatCurrencyWithoutSymbol(lot.maxBid)}</span>

        <animated.span className="change-label" style={{ opacity: spring.opacity }}>
          {t('lotList.maxBidUpdated')}
        </animated.span>
      </div>

      {multipleItems && lot.totalMaxBid && (
        <div className="text-tight">
          <small>
            {t('lotList.multiplicationEquation', {
              multiplicand: formatCurrencyWithoutSymbol(lot.maxBid),
              multiplier: formatInteger(lot.quantity),
              product: formatCurrencyWithoutSymbol(lot.totalMaxBid),
            })}
          </small>
        </div>
      )}

      <div className="text-tight">
        <small>
          <InternalLink className="text-ellipsis" title={lot.leadingBidderName} onClick={onBidderNameClick}>
            {t('lotList.by')} <span className="text-underline">{lot.leadingBidderName}</span>
          </InternalLink>
        </small>
      </div>
    </>
  );
};

export default MaxBid;
