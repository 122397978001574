import 'bootstrap/dist/css/bootstrap.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import 'config/i18next';
import 'styles/main.scss';

import AuthProvider from 'contexts/AuthProvider';
import { initApplicationInsights } from 'config/applicationInsights';
import authConfig from 'config/auth';
import App from './App';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') as string;
export const history = createBrowserHistory({ basename: baseUrl });

initApplicationInsights(history);

const onRedirectCallback = (targetUrl: string) => {
  history.replace(targetUrl || '');
};

ReactDOM.render(
  <AuthProvider
    authority={authConfig.authority}
    client_id={authConfig.client_id}
    scope={authConfig.scope}
    redirect_uri={window.location.origin}
    silent_redirect_uri={`${window.location.origin}/signin-silent.html`}
    onRedirectCallback={onRedirectCallback}
  >
    <Router history={history}>
      <Switch>
        <Route path="/:auctionRef" component={App} />
        <Route exact path="/" component={App} />
      </Switch>
    </Router>
  </AuthProvider>,
  document.getElementById('root'),
);
