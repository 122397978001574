export enum LotTextFilterOption {
  Number = 'Number',
  TitleOrDescription = 'TitleOrDescription',
  LeadingBidderName = 'LeadingBidderName',
}

export enum ReserveFilterOption {
  All = 'all',
  ReserveMet = 'met',
  ReserveNotMet = 'notMet',
}

export interface TextFilterDefinition {
  textFilterType: LotTextFilterOption;
  textFilter: string;
}

export interface LotFiltersDefinition extends Partial<TextFilterDefinition> {
  activeOnly?: boolean;
  reserveFilter?: ReserveFilterOption;
}
