import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';

import { I18nNamespace } from 'models/enums';
import { LotBidsHistory } from 'models/lotBidsHistory';
import { useAppContext } from 'contexts/AppContextProvider';
import { fetchLotBidsHistory } from 'api';

const sortByBidPlacedDesc = R.sortWith<LotBidsHistory>([R.descend(R.prop('bidPlacedDateUtc'))]);

const useLotBidsHistory = (lotId: string | undefined, dataTimestamp: number) => {
  const { t } = useTranslation(I18nNamespace.Common);
  const { auctionDetails } = useAppContext();
  const [lotBidsHistory, setLotBidsHistory] = useState<LotBidsHistory[]>();
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (!lotId) return;

    const resetState = () => {
      setLotBidsHistory(undefined);
      setIsLoading(true);
      setErrorMessage('');
    };

    resetState();
    fetchLotBidsHistory(auctionDetails.ref, lotId)
      .then(res => setLotBidsHistory(sortByBidPlacedDesc(res.data)))
      .catch(() => setErrorMessage(t('errorMessages.genericErrorMessage')))
      .finally(() => setIsLoading(false));
  }, [lotId, dataTimestamp, auctionDetails.ref, t]);

  return { lotBidsHistory, isLoading, errorMessage };
};

export default useLotBidsHistory;
