import React from 'react';
import * as R from 'ramda';

import useDashboardTranslation from 'hooks/useDashboardTranslation';
import { useAppContext } from 'contexts/AppContextProvider';
import { defaultTo0, formatCurrency } from 'utils/number';
import { Lot } from 'models/lot';
import { IncrementDetails } from 'models/auctionDetails';
import { updateLotIncrement } from 'api';

import AuctionIncrementLine from './AuctionIncrementLine';
import EditLotNumericValue from './EditLotNumericValue';

const getCurrentIncrement = (increments: IncrementDetails[], lot: Lot) => {
  const hammerPrice = defaultTo0(lot.hammerPrice);

  return increments.find(x => hammerPrice >= x.fromAmount && hammerPrice < R.defaultTo(Number.MAX_VALUE, x.toAmount));
};

interface EditLotIncrementProps {
  lot: Lot;
  isUpdating: boolean;
  onUpdate: (increment: number) => void;
}

const EditLotIncrement: React.FC<EditLotIncrementProps> = ({ lot, isUpdating, onUpdate }) => {
  const { t, tCommon } = useDashboardTranslation();
  const { auctionDetails } = useAppContext();

  const currentAuctionIncrement = getCurrentIncrement(auctionDetails.increments, lot);
  const currentIncrementValue = lot.increment ?? currentAuctionIncrement?.incrementValue;

  const handleUpdate = async (increment: number) => {
    await updateLotIncrement(auctionDetails.ref, lot.lotId, increment);
    onUpdate(increment);
  };

  const renderCurrentValue = () =>
    lot.increment ? (
      <>
        {t('editLot.lotIncrement')}{' '}
        <b className="font-weight-semi-bold">{formatCurrency(auctionDetails.currencyCode)(lot.increment)}</b>
      </>
    ) : (
      <div>
        {t('editLot.auctionIncrement')}
        <table className="edit-lot-increments" data-testid="edit-lot-increments">
          <tbody>
            {auctionDetails.increments.map(increment => (
              <AuctionIncrementLine
                key={`${increment.fromAmount}-${increment.toAmount}`}
                increment={increment}
                currentAuctionIncrement={currentAuctionIncrement}
              />
            ))}
          </tbody>
        </table>
      </div>
    );

  return (
    <EditLotNumericValue
      lot={lot}
      property="increment"
      isUpdating={isUpdating}
      currentValue={currentIncrementValue}
      validate={(val: string) => parseFloat(val) > 0 || tCommon('validation.min', { value: 0 })}
      renderCurrentValue={renderCurrentValue}
      onUpdate={handleUpdate}
    />
  );
};

export default EditLotIncrement;
