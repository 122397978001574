import React, { useState, useEffect, useRef, SyntheticEvent } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Validate } from 'react-hook-form/dist/types/form';
import { Button, InputGroup, InputGroupAddon, Input } from 'reactstrap';
import * as R from 'ramda';

import useDashboardTranslation from 'hooks/useDashboardTranslation';
import { defaultTo0 } from 'utils/number';
import { Lot } from 'models/lot';

import EditLotNumericValueConfirmationModal from './EditLotNumericValueConfirmationModal';

interface FormInputs {
  value: string;
}

interface EditLotNumericValueProps {
  lot: Lot;
  property: keyof Lot;
  isUpdating: boolean;
  currentValue?: number;
  validate?: Validate;
  renderCurrentValue?: () => JSX.Element | null;
  onUpdate: (value: number) => Promise<void>;
}

const EditLotNumericValue: React.FC<EditLotNumericValueProps> = ({
  lot,
  property,
  isUpdating,
  validate,
  currentValue,
  renderCurrentValue,
  onUpdate,
}) => {
  const { t, tCommon } = useDashboardTranslation();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const formMethods = useForm<FormInputs>();
  const { register, handleSubmit, setValue, errors, getValues, reset } = formMethods;
  const inputValueRef = useRef('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    reset();
  }, [reset, lot.lotId]);

  const handleCutInHalf = () => setValue('value', R.max(0.01, defaultTo0(currentValue) / 2).toFixed(2));

  const handleOnInput = (e: SyntheticEvent) => {
    const input = e.target as HTMLInputElement;
    const isValid = /^\d{0,9}(\.\d{0,2})?$/.test(input.value);
    const effectiveValue = isValid ? input.value : inputValueRef.current;
    setValue(input.name, effectiveValue);
    inputValueRef.current = effectiveValue;
    setErrorMessage('');
  };

  const onSubmit = () => {
    setErrorMessage('');
    setIsConfirmationModalOpen(true);
  };

  const onConfirm = async () => {
    const { value } = getValues();

    try {
      await onUpdate(parseFloat(value));
      reset();
    } catch (err) {
      setErrorMessage(err.response?.status === 408 ? tCommon('errorMessages.timeout') : t('editLot.errorUpdating'));
    } finally {
      setIsConfirmationModalOpen(false);
    }
  };

  return (
    <>
      <div className="edit-lot-content-heading">
        <hr />
        <div className="edit-lot-content-heading-label">{t(`editLot.${String(property)}`)}</div>
      </div>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)} data-testid={`${String(property)}-form`}>
          {renderCurrentValue?.()}
          {!!currentValue && (
            <Button type="button" color="link" className="d-block pl-0 pr-0" onClick={handleCutInHalf}>
              {t('editLot.cutInHalf')}
            </Button>
          )}
          <div className="mb-2">
            <InputGroup>
              <Input
                type="text"
                name="value"
                innerRef={register({
                  required: tCommon('validation.required'),
                  validate,
                })}
                onInput={handleOnInput}
                data-testid={`${String(property)}-value`}
              />
              <InputGroupAddon addonType="append">
                <Button color="primary" type="submit" disabled={isUpdating}>
                  {t(isUpdating ? 'editLot.updating' : 'editLot.update')}
                </Button>
              </InputGroupAddon>
            </InputGroup>
            {errors.value && <span className="text-danger">{errors.value.message}</span>}
            {errorMessage && <span className="text-danger">{errorMessage}</span>}
          </div>
        </form>
        <EditLotNumericValueConfirmationModal
          isOpen={isConfirmationModalOpen}
          lotNumber={lot.number}
          property={property}
          onClose={() => setIsConfirmationModalOpen(false)}
          onConfirm={onConfirm}
        />
      </FormProvider>
    </>
  );
};

export default EditLotNumericValue;
