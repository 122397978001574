import React, { useState } from 'react';
import getSymbolFromCurrency from 'currency-symbol-map';
import * as R from 'ramda';
import { Badge } from 'reactstrap';
import { Trans } from 'react-i18next';

import useDashboardTranslation from 'hooks/useDashboardTranslation';
import { useAppContext } from 'contexts/AppContextProvider';
import { formatDateWithTimezone } from 'utils/date';
import InternalLink from 'components/InternalLink/InternalLink';

import './AuctionDetailsInfo.scss';
import PendingBiddersModal from './PendingBiddersModal';

const AuctionDetailsInfo: React.FC = () => {
  const { t } = useDashboardTranslation();
  const { auctionDetails } = useAppContext();
  const [isPendingBiddersModalOpen, setIsPendingBiddersModalOpen] = useState(false);

  const pendingBidders = R.map(R.prop('pending'), auctionDetails.bidders);
  const pendingBiddersCount = R.reduce<number, number>(R.add, 0, pendingBidders);

  return (
    <>
      <div className="auction-details-info">
        <div className="auction-dates">
          <Trans
            t={t}
            i18nKey="auctionDetails.auctionDates"
            values={{
              starts: formatDateWithTimezone(auctionDetails.startDateUtc, auctionDetails.timezone),
              endsFrom: formatDateWithTimezone(auctionDetails.endsFromUtc, auctionDetails.timezone),
            }}
          />
        </div>
        <div className="auction-details-info-indicators">
          {Boolean(pendingBiddersCount) && (
            <InternalLink className="pending-bidders" onClick={() => setIsPendingBiddersModalOpen(true)}>
              <span className="icon-approve" />
              <span className="text-underline">
                {t('auctionDetails.pendingBiddersInfo', { value: pendingBiddersCount })}
              </span>
            </InternalLink>
          )}
          {auctionDetails.piecemeal && (
            <Badge color="warning" className="text-uppercase ml-2" pill>
              {t('auctionDetails.piecemeal')}
            </Badge>
          )}
          <Badge className="text-uppercase ml-2" pill>
            {t('auctionDetails.currency', {
              code: auctionDetails.currencyCode,
              symbol: getSymbolFromCurrency(auctionDetails.currencyCode),
            })}
          </Badge>
        </div>
      </div>
      {isPendingBiddersModalOpen && (
        <PendingBiddersModal isOpen={isPendingBiddersModalOpen} onClose={() => setIsPendingBiddersModalOpen(false)} />
      )}
    </>
  );
};

export default AuctionDetailsInfo;
