import React, { useState, useEffect } from 'react';
import * as R from 'ramda';

import useDashboardTranslation from 'hooks/useDashboardTranslation';
import { useAppContext } from 'contexts/AppContextProvider';
import { Lot } from 'models/lot';
import ScrollableContent from 'components/ScrollableContent/ScrollableContent';

import './EditLot.scss';
import EditLotIncrement from './EditLotIncrement';
import EditLotOpeningPrice from './EditLotOpeningPrice';
import EditLotReserve from './EditLotReserve';

interface EditLotProps {
  lot: Lot;
  maxHeight: number;
}

const EditLot: React.FC<EditLotProps> = ({ lot, maxHeight }) => {
  const { t } = useDashboardTranslation();
  const { lotUpdates, addLotUpdate } = useAppContext();
  const [effectiveLot, setEffectiveLot] = useState(lot);

  useEffect(() => {
    setEffectiveLot(lot);
  }, [lot]);

  const handleLotUpdate = R.curry((property: keyof Lot, value: number) => {
    if (value === effectiveLot[property]) return;

    setEffectiveLot(l => ({ ...l, [property]: value }));
    addLotUpdate(lot.lotId, { [property]: value });
  });

  const isUpdating = lotUpdates[lot.lotId] && Object.values(lotUpdates[lot.lotId]).some(Boolean);

  return (
    <div className="edit-lot">
      <div className="side-panel-heading">{t('editLot.lotNumber', { lotNumber: lot.number })}</div>
      <ScrollableContent maxHeight={maxHeight}>
        <div className="edit-lot-content">
          <h5 className="text-center">{t('editLot.title')}</h5>
          <EditLotIncrement lot={effectiveLot} isUpdating={isUpdating} onUpdate={handleLotUpdate('increment')} />
          <EditLotOpeningPrice lot={effectiveLot} isUpdating={isUpdating} onUpdate={handleLotUpdate('openingPrice')} />
          <EditLotReserve lot={effectiveLot} isUpdating={isUpdating} onUpdate={handleLotUpdate('reservePrice')} />
        </div>
      </ScrollableContent>
    </div>
  );
};

export default EditLot;
