import React from 'react';
import { ButtonGroup, Button } from 'reactstrap';
import classNames from 'classnames';

import useDashboardTranslation from 'hooks/useDashboardTranslation';

import { ReserveFilterOption } from '../models/lotFilters';

interface ReserveFilterProps {
  currentFilter: ReserveFilterOption;
  onClick: (option: ReserveFilterOption) => void;
}

const ReserveFilter: React.FC<ReserveFilterProps> = ({ currentFilter, onClick }) => {
  const { t } = useDashboardTranslation();

  return (
    <div className="reserve-filter">
      <span className="mr-3">{t('lotList.showLots')}</span>
      <ButtonGroup>
        <Button
          color="primary"
          className={classNames('reserve-filter-button', { active: currentFilter === ReserveFilterOption.All })}
          onClick={() => onClick(ReserveFilterOption.All)}
        >
          {t('lotList.all')}
        </Button>
        <Button
          color="primary"
          className={classNames('reserve-filter-button', { active: currentFilter === ReserveFilterOption.ReserveMet })}
          onClick={() => onClick(ReserveFilterOption.ReserveMet)}
        >
          <span className="icon-check-circle" /> {t('lotList.reserveMet')}
        </Button>
        <Button
          color="primary"
          className={classNames('reserve-filter-button', {
            active: currentFilter === ReserveFilterOption.ReserveNotMet,
          })}
          onClick={() => onClick(ReserveFilterOption.ReserveNotMet)}
        >
          <span className="icon-arrow-circle-o-down" /> {t('lotList.reserveNotMet')}
        </Button>
      </ButtonGroup>
    </div>
  );
};

export default ReserveFilter;
