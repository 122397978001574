import React from 'react';

import useDashboardTranslation from 'hooks/useDashboardTranslation';
import { useAppContext } from 'contexts/AppContextProvider';
import { formatCurrency, formatInteger } from 'utils/number';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';

import useBidderSaleActivity from '../hooks/useBidderSaleActivity';

interface BidderInfoAuctionActivityTabProps {
  bidderId?: string;
}

const BidderInfoAuctionActivityTab: React.FC<BidderInfoAuctionActivityTabProps> = ({ bidderId }) => {
  const { t } = useDashboardTranslation();
  const {
    auctionDetails: { currencyCode, showMaxBids },
  } = useAppContext();
  const { saleActivity, isLoading, errorMessage } = useBidderSaleActivity(bidderId);

  if (isLoading) return <LoadingIndicator />;
  if (!saleActivity) return <p>{errorMessage}</p>;

  const asCurrency = formatCurrency(currencyCode);

  return (
    <div className="auction-activity">
      <div className="auction-activity-heading">
        <hr />
        <div className="auction-activity-heading-label">{t('bidderInfo.activeLots')}</div>
      </div>
      <dl>
        <dt>{t('bidderInfo.totalHammerActiveLots')}</dt>
        <dd>{asCurrency(saleActivity.totalActiveLeadingHammer)}</dd>
        {showMaxBids && saleActivity.totalActiveLeadingMaxBid != null && (
          <>
            <dt>{t('bidderInfo.maxBidsActiveLots')}</dt>
            <dd>{asCurrency(saleActivity.totalActiveLeadingMaxBid)}</dd>
          </>
        )}
      </dl>
      <div className="auction-activity-heading">
        <hr />
        <div className="auction-activity-heading-label">{t('bidderInfo.closedLots')}</div>
      </div>
      <dl>
        <dt>{t('bidderInfo.numberOfLotsWon')}</dt>
        <dd>{formatInteger(saleActivity.wonLots)}</dd>
        <dt>{t('bidderInfo.totalHammerLotsWon')}</dt>
        <dd>{asCurrency(saleActivity.totalWonHammer)}</dd>
      </dl>
    </div>
  );
};

export default BidderInfoAuctionActivityTab;
