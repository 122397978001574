import { Lot } from 'models/lot';

export enum SidePanelContentType {
  BidderInfo,
  BidsHistory,
  EditLot,
}

export interface SidePanelContent {
  type: SidePanelContentType;
  lot: Lot;
  timestamp: number;
}

export const createSidePanelContent = (type: SidePanelContentType, lot: Lot): SidePanelContent => ({
  type,
  lot,
  timestamp: Date.now(),
});
