import React from 'react';

import { formatCurrencyWithoutSymbol } from 'utils/number';
import { LotAggregations } from 'models/lot';
import useDashboardTranslation from 'hooks/useDashboardTranslation';

import './LotsAggregations.scss';
import { Trans } from 'react-i18next';

interface LotAggregationValue {
  labelI18nKey: string;
  valueKey: keyof LotAggregations;
}

const aggregationValuesDefinition: LotAggregationValue[] = [
  { labelI18nKey: 'lotList.aggregations.reserve', valueKey: 'totalReserve' },
  { labelI18nKey: 'lotList.aggregations.hammer', valueKey: 'totalHammer' },
  { labelI18nKey: 'lotList.aggregations.maxBids', valueKey: 'totalMaxBids' },
];

interface LotsAggregationsProps {
  aggregations: LotAggregations;
}

const LotsAggregations: React.FC<LotsAggregationsProps> = ({ aggregations }) => {
  const { t } = useDashboardTranslation();
  return (
    <div className="lots-aggregations">
      <div className="lots-aggregations-title">{t('lotList.aggregations.title')}</div>
      <div>
        {aggregationValuesDefinition.map(
          ({ labelI18nKey, valueKey }) =>
            aggregations &&
            aggregations[valueKey] != null && (
              <span key={valueKey} className="lots-aggregations-value">
                <Trans
                  t={t}
                  i18nKey={labelI18nKey}
                  values={{ value: formatCurrencyWithoutSymbol(aggregations[valueKey]!) }}
                />
              </span>
            ),
        )}
      </div>
    </div>
  );
};

export default LotsAggregations;
