import axios, { AxiosResponse } from 'axios';

import { apiBaseUrl } from 'config/api';
import { LotQueryOptions, LotQueryResponse } from 'models/lot';
import { AuctionDetails } from 'models/auctionDetails';
import { BidderDetails } from 'models/bidderDetails';
import { BidderSaleActivity } from 'models/bidderSaleActivity';
import { BidderHistory } from 'models/bidderHistory';
import { LotBidsHistory } from 'models/lotBidsHistory';
import { ActiveBidders } from 'models/activeBidders';
import { PendingBidder } from 'models/pendingBidder';

type ApiResponse<T> = Promise<AxiosResponse<T>>;

const apiClient = axios.create({
  baseURL: apiBaseUrl,
});

export const setAuthorizationHeader = (token: string) => {
  apiClient.defaults.headers = { Authorization: `Bearer ${token}` };
};

export const fetchCurrentTime = (): ApiResponse<number> => apiClient.get('current-time');

export const fetchAuction = (auctionRef: string, timestamp?: number): ApiResponse<AuctionDetails> =>
  apiClient.get(`auctions/${auctionRef}`, {
    params: { timestamp },
    validateStatus: status => status < 400,
  });

export const fetchLots = (
  auctionRef: string,
  options: LotQueryOptions,
  timestamp?: number,
): ApiResponse<LotQueryResponse> =>
  apiClient.get(`auctions/${auctionRef}/lots`, {
    params: { ...options, timestamp },
    validateStatus: status => status < 400,
  });

export const fetchBidderDetails = (auctionRef: string, bidderId: string): ApiResponse<BidderDetails> =>
  apiClient.get(`auctions/${auctionRef}/bidders/${bidderId}`);

export const fetchBidderSaleActivity = (auctionRef: string, bidderId: string): ApiResponse<BidderSaleActivity> =>
  apiClient.get(`auctions/${auctionRef}/bidders/${bidderId}/sale-activity`);

export const fetchCustomerHistory = (
  clientId: string,
  auctionRef: string,
  customerId: string,
): ApiResponse<BidderHistory> =>
  apiClient.get(`customers/${customerId}/clients/${clientId}/history`, { params: { auctionRef } });

export const fetchLotBidsHistory = (auctionRef: string, lotId: string): ApiResponse<LotBidsHistory[]> =>
  apiClient.get(`auctions/${auctionRef}/lots/${lotId}/bid-history`);

export const fetchActiveBiddersCount = (auctionRef: string): ApiResponse<ActiveBidders[]> =>
  apiClient.get(`auctions/${auctionRef}/bidders/lately-active`);

export const fetchPendingBidders = (auctionRef: string): ApiResponse<PendingBidder[]> =>
  apiClient.get(`auctions/${auctionRef}/bidders/pending`);

export const updateLotIncrement = (auctionRef: string, lotId: string, increment: number) =>
  apiClient.post(`auctions/${auctionRef}/lots/${lotId}/increment`, { value: increment });

export const updateLotOpeningPrice = (auctionRef: string, lotId: string, openingPrice: number) =>
  apiClient.post(`auctions/${auctionRef}/lots/${lotId}/opening-price`, { value: openingPrice });

export const updateLotReserve = (auctionRef: string, lotId: string, reserve: number) =>
  apiClient.post(`auctions/${auctionRef}/lots/${lotId}/reserve`, { value: reserve });

export const approveBidder = (auctionRef: string, bidderId: string): ApiResponse<string[] | undefined> =>
  apiClient.post(`auctions/${auctionRef}/bidders/${bidderId}/approve`);
