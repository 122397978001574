import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { History } from 'history';

const instrumentationKey = process.env.REACT_APP_APPLICATION_INSIGHTS_INSTRUMENTATION_KEY;

let appInsights: ApplicationInsights;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const initApplicationInsights = (history: History<any>) => {
  const reactPlugin = new ReactPlugin();
  appInsights = new ApplicationInsights({
    config: {
      instrumentationKey,
      enableCorsCorrelation: true,
      extensions: [reactPlugin],
      extensionConfig: {
        [reactPlugin.identifier]: { history },
      },
    },
  });

  appInsights.loadAppInsights();
  const cloudRoleNameInitializer = (item: ITelemetryItem) => {
    // eslint-disable-next-line no-param-reassign
    item.tags = item.tags || [];
    item.tags.push({ 'ai.cloud.role': 'Timed.Dashboard.SPA' });
  };
  appInsights.addTelemetryInitializer(cloudRoleNameInitializer);
};

export const setApplicationInsightsUser = (userId: string) => {
  appInsights.setAuthenticatedUserContext(userId);
};
