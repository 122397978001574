import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { I18nNamespace } from 'models/enums';
import { AuctionDetails } from 'models/auctionDetails';
import { shortPollingTimespan } from 'config/api';
import { fetchAuction } from 'api';

const useAuctionDetails = (auctionRef: string | undefined) => {
  const { t } = useTranslation(I18nNamespace.Common);
  const [auctionDetails, setAuctionDetails] = useState<AuctionDetails>();
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [retryCount, setRetryCount] = useState(0);

  useEffect(() => {
    if (!auctionRef) return undefined;

    let timeout: number;
    const fetchAuctionDetails = async (timestamp?: number) => {
      try {
        const response = await fetchAuction(auctionRef, timestamp);
        if (response.status === 304) {
          timeout = window.setTimeout(() => fetchAuctionDetails(timestamp), shortPollingTimespan);
          return;
        }
        setAuctionDetails(response.data);
        setErrorMessage('');
        timeout = window.setTimeout(() => fetchAuctionDetails(response.data.dataTimestamp), shortPollingTimespan);
      } catch (error) {
        if (retryCount < 3 && timestamp == null) throw error;

        if ([401, 403].includes(error.response?.status)) {
          setErrorMessage(t('errorMessages.noAccess'));
          return;
        }

        if (error.response?.status === 404) {
          setErrorMessage(t('errorMessages.auctionNotFound', { auctionRef }));
          return;
        }

        setErrorMessage(t('errorMessages.genericErrorMessage'));
      }
    };

    setIsLoading(true);
    fetchAuctionDetails()
      .then(() => setIsLoading(false))
      .catch(() => {
        timeout = window.setTimeout(() => setRetryCount(retryCount + 1), shortPollingTimespan);
      });

    return () => {
      clearTimeout(timeout);
    };
  }, [auctionRef, t, retryCount]);

  return { auctionDetails, isLoading, errorMessage };
};

export default useAuctionDetails;
