import BidSpotterComLogo from 'assets/images/bidspotter-com_logo.png';
import BidSpotterCoUkLogo from 'assets/images/bidspotter-co-uk_logo.png';
import AtgLogo from 'assets/images/atg_logo.png';
import IBidderLogo from 'assets/images/i-bidder-com_logo.png';
import TheSaleroomLogo from 'assets/images/the-saleroom-com_logo.png';

const logos = {
  ATG: {
    alt: 'Auction Technology Group',
    img: AtgLogo,
  },
  BSC: {
    alt: 'BidSpotter.com',
    img: BidSpotterComLogo,
  },
  BS: {
    alt: 'BidSpotter.co.uk',
    img: BidSpotterCoUkLogo,
  },
  IB: {
    alt: 'i-bidder.com',
    img: IBidderLogo,
  },
  SR: {
    alt: 'the saleroom',
    img: TheSaleroomLogo,
  },
};

export default logos;
