import React, { useState, useMemo, useEffect } from 'react';
import { Container, Col } from 'reactstrap';
import ReactPaginate from 'react-paginate';
import * as R from 'ramda';

import './AuctionLots.scss';

import useDashboardTranslation from 'hooks/useDashboardTranslation';
import { useAppContext } from 'contexts/AppContextProvider';
import { Lots } from 'models/auctionDetails';
import SyncInfo from 'components/SyncInfo/SyncInfo';

import useLots, { LotSortOption } from './hooks/useLots';
import usePreventRowAnimation from './hooks/usePreventRowAnimation';
import { LotFiltersDefinition } from './models/lotFilters';
import { SidePanelContent } from './models/sidePanel';
import LotFilters from './LotFilters/LotFilters';
import LotList from './LotList/LotList';
import SidePanel from './SidePanel/SidePanel';
import LotsAggregations from './LotsAggregations';

const sumLots = R.compose(
  R.sum,
  R.map<Lots, number>(x => x.total),
);

interface AuctionLotsProps {
  height: string;
}

const AuctionLots: React.FC<AuctionLotsProps> = ({ height }) => {
  const { auctionDetails } = useAppContext();
  const { t, tCommon } = useDashboardTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const [sortBy, setSortBy] = useState(LotSortOption.TimeLeftAsc);
  const [filters, setFilters] = useState<LotFiltersDefinition>({ activeOnly: true });
  const { resolvedData: lots, latestData, updatedAt } = useLots(auctionDetails.ref, {
    pageNumber: currentPage,
    sortBy,
    filters,
  });
  const { preventRowAnimations, withoutRowAnimations } = usePreventRowAnimation(updatedAt);
  const lotCount = useMemo(() => sumLots(auctionDetails.lots), [auctionDetails]);
  const [sidePanelContent, setSidePanelContent] = useState<SidePanelContent>();

  useEffect(() => {
    if (!latestData) return;

    setCurrentPage(latestData.currentPage);
  }, [latestData]);

  if (!lots) return null;
  if (lotCount === 0) return <SyncInfo />;

  const pageCount = Math.ceil(lots.totalDocs / lots.pageSize);

  return (
    <Container className="auction-lots" style={{ height }}>
      <div className="overflow-hidden">
        <LotFilters initialFilters={filters} onFiltersChange={x => withoutRowAnimations(() => setFilters(x))} />
        {lots.totalDocs > 0 ? (
          <div className="d-flex h-100">
            <Col xs={sidePanelContent ? 9 : 12} className="h-100 p-0">
              <LotList
                lots={lots}
                sortBy={sortBy}
                preventRowAnimations={preventRowAnimations}
                isSidePanelOpen={!!sidePanelContent}
                onSortByChange={sortOption => withoutRowAnimations(() => setSortBy(sortOption))}
                onSidePanelOpen={(content: SidePanelContent) => setSidePanelContent(content)}
              />
            </Col>
            {sidePanelContent && (
              <Col xs={3}>
                <SidePanel content={sidePanelContent} onClose={() => setSidePanelContent(undefined)} />
              </Col>
            )}
          </div>
        ) : (
          <p className="text-center p-4">{t('lotList.noLotsInfo')}</p>
        )}
      </div>

      {lots.totalDocs > 0 && (
        <div className="d-flex justify-content-between mt-3 container">
          <div data-testid="pagination">
            {pageCount > 1 && (
              <ReactPaginate
                containerClassName="pagination"
                pageCount={pageCount}
                forcePage={currentPage - 1}
                onPageChange={({ selected }) => withoutRowAnimations(() => setCurrentPage(selected + 1))}
                pageRangeDisplayed={3}
                marginPagesDisplayed={1}
                previousLabel={tCommon('pagination.previous')}
                nextLabel={tCommon('pagination.next')}
              />
            )}
          </div>
          <LotsAggregations aggregations={lots.aggregations} />
        </div>
      )}
    </Container>
  );
};

export default AuctionLots;
