import React from 'react';
import { Container, Navbar, NavbarBrand, NavbarText, Nav, NavItem } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { I18nNamespace } from 'models/enums';
import { useAuth } from 'contexts/AuthProvider';
import { useAppContext } from 'contexts/AppContextProvider';

import './NavMenu.scss';
import logos from './logos';

const formatTitle = (title: string) => (title.length > 45 ? `${title.slice(0, 45)}...` : title);

const NavMenu: React.FC = () => {
  const { t } = useTranslation(I18nNamespace.Common);
  const { user } = useAuth();
  const { auctionDetails } = useAppContext();

  const userName = `${user?.profile.atg_user_first_name ?? ''} ${user?.profile.atg_user_last_name ?? ''}`.trim();
  const logo = logos[auctionDetails.parentPlatformCode] ?? logos.ATG;

  return (
    <header className="atg-header">
      <Navbar className="navbar-expand-sm navbar-toggleable-sm box-shadow atg-navbar" light>
        <Container>
          <NavbarBrand className="mr-4">
            <img src={logo.img} alt={logo.alt} />
          </NavbarBrand>
          <Nav className="atg-nav" navbar>
            <NavItem>
              <a href={auctionDetails.link} className="auction-link" target="_blank" rel="noreferrer noopener">
                <strong title={auctionDetails.title}>{formatTitle(auctionDetails.title)}</strong>{' '}
                <span className="auction-ref">({auctionDetails.ref})</span>
              </a>
            </NavItem>
          </Nav>

          {userName && <NavbarText>{t('helloUser', { name: userName })}</NavbarText>}
        </Container>
      </Navbar>
    </header>
  );
};

export default NavMenu;
