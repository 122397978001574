import React, { useState } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classNames from 'classnames';

import useDashboardTranslation from 'hooks/useDashboardTranslation';
import { useAppContext } from 'contexts/AppContextProvider';

import './AuctionStats.scss';

import TotalHammerSummary from './TotalHammerSummary';
import TotalMaxBidsSummary from './TotalMaxBidsSummary';
import BiddersSummary from './BiddersSummary';
import LotsSummary from './LotsSummary';

enum AuctionStatsTab {
  TotalHammer = 'total-hammer',
  TotalMaxBids = 'total-max-bids',
  Bidders = 'bidders',
  Lots = 'lots',
}

const tabsDefinition = [
  {
    key: AuctionStatsTab.TotalHammer,
    labelI18nKey: 'auctionDetails.totalHammer',
    component: TotalHammerSummary,
  },
  {
    key: AuctionStatsTab.TotalMaxBids,
    labelI18nKey: 'auctionDetails.totalMaxBids',
    component: TotalMaxBidsSummary,
  },
  {
    key: AuctionStatsTab.Lots,
    labelI18nKey: 'auctionDetails.lots',
    component: LotsSummary,
  },
  {
    key: AuctionStatsTab.Bidders,
    labelI18nKey: 'auctionDetails.bidders',
    component: BiddersSummary,
  },
];

const AuctionStats: React.FC = () => {
  const { t } = useDashboardTranslation();
  const { auctionDetails } = useAppContext();
  const [activeTab, setActiveTab] = useState(tabsDefinition[0].key);

  const tabsToDisplay = auctionDetails.showMaxBids
    ? tabsDefinition
    : tabsDefinition.filter(({ key }) => key !== AuctionStatsTab.TotalMaxBids);

  return (
    <div className="auction-stats">
      <Nav tabs className="w-100 justify-content-center">
        {tabsToDisplay.map(tab => (
          <NavItem key={tab.key}>
            <NavLink
              className={classNames({ active: activeTab === tab.key })}
              onClick={() => setActiveTab(tab.key)}
              data-testid={tab.key}
            >
              {t(tab.labelI18nKey)}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <TabContent activeTab={activeTab} className="w-100">
        {tabsToDisplay.map(tab => (
          <TabPane key={tab.key} tabId={tab.key}>
            <tab.component auctionDetails={auctionDetails} />
          </TabPane>
        ))}
      </TabContent>
    </div>
  );
};

export default AuctionStats;
