import * as R from 'ramda';

export const capitalizeFirstLetter: (s: string) => string = R.replace(/^./, R.toUpper);

export const toInteger = (value: string | undefined): number | undefined => {
  if (value == null || Number.isNaN(parseInt(value, 10))) return undefined;

  return parseInt(value, 10);
};

export const toBoolean = (value: string | undefined): boolean => value?.toLowerCase() === 'true';
