import { useEffect } from 'react';
import { useSpring } from 'react-spring';
import { usePrevious } from 'react-use';
import moment from 'moment';

import { Lot } from 'models/lot';

const animationTime = 3000;

const backgroundColorAnimation = {
  to: async (next: Function) => {
    await next({ backgroundColor: 'rgb(238, 250, 255)' });
    await next({ backgroundColor: 'white', delay: animationTime });
  },
  from: { backgroundColor: 'white' },
};

const useBackgroundColorAnimation = (lot: Lot, prevent: boolean) => {
  const [backgroundColorSpring, setBackgroundColorSpring] = useSpring(() => ({ backgroundColor: 'white' }));

  useEffect(() => {
    if (prevent) return;

    setBackgroundColorSpring(backgroundColorAnimation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lot.hammerPrice, lot.maxBid, lot.endTimeUtc]);

  return backgroundColorSpring;
};

const cellUpdateAnimation = {
  to: async (next: Function) => {
    await next({ color: 'green', opacity: 1 });
    await next({ color: 'black', opacity: 0, delay: animationTime });
  },
  from: { color: 'black', opacity: 0 },
};

const useTimeLeftAnimation = (lot: Lot, previousLot: Lot | undefined, prevent: boolean) => {
  const [timeLeftSpring, setTimeLeftSpring] = useSpring(() => ({ color: 'black', opacity: 0 }));

  useEffect(() => {
    if (prevent || previousLot == null || moment.utc().isAfter(moment.utc(lot.endTimeUtc))) return;

    setTimeLeftSpring(cellUpdateAnimation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lot.endTimeUtc]);

  return timeLeftSpring;
};

const useHammerPriceAnimation = (lot: Lot, previousLot: Lot | undefined, prevent: boolean) => {
  const [hammerPriceSpring, setHammerPriceSpring] = useSpring(() => ({ color: 'black', opacity: 0 }));

  useEffect(() => {
    if (prevent || previousLot == null) return;

    setHammerPriceSpring(cellUpdateAnimation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lot.hammerPrice]);

  return hammerPriceSpring;
};

const useMaxBidAnimation = (lot: Lot, previousLot: Lot | undefined, prevent: boolean) => {
  const [maxBidSpring, setMaxBid] = useSpring(() => ({ color: 'black', opacity: 0 }));

  useEffect(() => {
    if (prevent || previousLot == null) return;

    setMaxBid(cellUpdateAnimation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lot.maxBid]);

  return maxBidSpring;
};

const useLotRowAnimations = (lot: Lot, prevent: boolean) => {
  const previousLot = usePrevious(lot);
  const backgroundColorSpring = useBackgroundColorAnimation(lot, prevent);
  const timeLeftSpring = useTimeLeftAnimation(lot, previousLot, prevent);
  const hammerPriceSpring = useHammerPriceAnimation(lot, previousLot, prevent);
  const maxBidSpring = useMaxBidAnimation(lot, previousLot, prevent);

  return { backgroundColorSpring, timeLeftSpring, hammerPriceSpring, maxBidSpring };
};

export default useLotRowAnimations;
