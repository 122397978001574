import React from 'react';
import { Col, Badge } from 'reactstrap';
import { animated } from 'react-spring';

import { editLotEnabled } from 'config/featureToggles';
import { Lot } from 'models/lot';
import { formatInteger } from 'utils/number';
import useDashboardTranslation from 'hooks/useDashboardTranslation';
import { useAppContext } from 'contexts/AppContextProvider';
import InternalLink from 'components/InternalLink/InternalLink';

import './LotRow.scss';
import useLotTimers from '../hooks/useLotTimers';
import useLotRowAnimations from '../hooks/useLotRowAnimations';
import { SidePanelContentType, SidePanelContent, createSidePanelContent } from '../models/sidePanel';
import HammerPrice from './HammerPrice';
import MaxBid from './MaxBid';
import LeadingBidder from './LeadingBidder';

const defaultImgUrl = 'https://portal-images.azureedge.net/tenants/shared/image-not-found.png';

interface LotRowProps {
  lot: Lot;
  preventAnimations: boolean;
  narrowImage: boolean;
  onSidePanelOpen: (content: SidePanelContent) => void;
}

const LotRow: React.FC<LotRowProps> = ({ lot, preventAnimations, narrowImage, onSidePanelOpen }) => {
  const { t } = useDashboardTranslation();
  const { auctionDetails } = useAppContext();
  const { timeLeft, timeFromLastBid } = useLotTimers(lot);
  const { backgroundColorSpring, timeLeftSpring, hammerPriceSpring, maxBidSpring } = useLotRowAnimations(
    lot,
    preventAnimations,
  );

  const imageWidth = narrowImage ? 125 : 175;
  const imageSrc = `${lot.imageUrl || defaultImgUrl}?h=125&w=${imageWidth}`;
  const lotUrl = auctionDetails.link ? `${auctionDetails.link}/lot-${lot.lotId}` : undefined;
  const multipleItems = auctionDetails.piecemeal && lot.quantity > 1;
  const title = multipleItems
    ? t('lotList.titleWithQuantity', { title: lot.title, quantity: lot.quantity })
    : lot.title;
  const isLotActive = timeLeft != null;

  return (
    <animated.div style={backgroundColorSpring} className="lot-row row" data-testid="lot-row">
      <Col xs="3" md="2" className="lot-image-container">
        <a href={lotUrl} target="_blank" rel="noopener noreferrer">
          <img src={imageSrc} alt={title} className="lot-image" data-testid="lot-thumbnail" />
          <div className="overlay" title={lot.number}>
            {lot.number}
          </div>
        </a>
      </Col>
      <Col xs="3" md="2" title={title} data-testid="lot-title">
        <a href={lotUrl} target="_blank" rel="noopener noreferrer">
          {title}
        </a>
      </Col>
      <animated.div className="col-2" data-testid="lot-time-left" style={{ color: timeLeftSpring.color }}>
        {isLotActive ? (
          <span className="mr-2" style={timeLeft!.style}>
            {timeLeft!.value}
          </span>
        ) : (
          <Badge pill color="danger" className="text-uppercase mr-2">
            {t('lotList.closed')}
          </Badge>
        )}
        <animated.span className="change-label" style={{ opacity: timeLeftSpring.opacity }}>
          {t('lotList.timeExtended')}
        </animated.span>
      </animated.div>
      <animated.div className="col-2" data-testid="lot-hammer-price" style={{ color: hammerPriceSpring.color }}>
        <HammerPrice
          lot={lot}
          timeFromLastBid={timeFromLastBid}
          multipleItems={multipleItems}
          showReserve={auctionDetails.showReserves}
          spring={hammerPriceSpring}
        />
      </animated.div>
      <animated.div className="col-2" data-testid="lot-max-bid" style={{ color: maxBidSpring.color }}>
        {auctionDetails.showMaxBids ? (
          <MaxBid
            lot={lot}
            multipleItems={multipleItems}
            spring={maxBidSpring}
            onBidderNameClick={() => onSidePanelOpen(createSidePanelContent(SidePanelContentType.BidderInfo, lot))}
          />
        ) : (
          <LeadingBidder
            leadingBidderName={lot.leadingBidderName}
            spring={maxBidSpring}
            onBidderNameClick={() => onSidePanelOpen(createSidePanelContent(SidePanelContentType.BidderInfo, lot))}
          />
        )}
      </animated.div>
      <Col md="1" className="d-none d-md-block" data-testid="lot-bids-count">
        {lot.bidsCount > 0 ? (
          <InternalLink onClick={() => onSidePanelOpen(createSidePanelContent(SidePanelContentType.BidsHistory, lot))}>
            <span className="text-underline">{formatInteger(lot.bidsCount)}</span>
          </InternalLink>
        ) : (
          formatInteger(lot.bidsCount)
        )}
      </Col>
      <Col xs="1" className="d-none d-md-block" data-testid="lot-watch-count">
        {formatInteger(lot.watchCount)}
      </Col>
      {editLotEnabled && isLotActive && (
        <InternalLink
          className="edit-lot"
          testId="edit-lot"
          onClick={() => onSidePanelOpen(createSidePanelContent(SidePanelContentType.EditLot, lot))}
        >
          <span className="icon-edit" />
        </InternalLink>
      )}
    </animated.div>
  );
};

export default LotRow;
