import React, { useState, useEffect } from 'react';
import { UserManager, UserManagerSettings, User, WebStorageStateStore, Log } from 'oidc-client';

import { setAuthorizationHeader } from 'api';
import { setApplicationInsightsUser } from 'config/applicationInsights';

if (process.env.NODE_ENV === 'development') {
  Log.logger = console;
}

interface AuthContext {
  isLoading: boolean;
  isAuthenticated: boolean;
  user: User | undefined;
  loginWithRedirect: () => Promise<void>;
}

export const AuthContext = React.createContext<AuthContext | undefined>(undefined);

export const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (context === undefined) throw new Error('useAuth must be used within a <AuthProvider>');

  return context;
};

interface AuthProviderProps extends UserManagerSettings {
  children: JSX.Element;
  onRedirectCallback: (state: string) => void;
}

const AuthProvider = ({ children, onRedirectCallback, ...initOptions }: AuthProviderProps) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<User>();
  const [authClient, setAuthClient] = useState<UserManager>();

  useEffect(() => {
    const createClient = () => {
      const client = new UserManager({
        ...initOptions,
        // eslint-disable-next-line @typescript-eslint/camelcase
        response_type: 'code',
        userStore: new WebStorageStateStore({ store: window.localStorage }),
      });

      const renewTokenSilently = async () => {
        try {
          const response = await client.signinSilent();
          setAuthorizationHeader(response.access_token);
        } catch {
          client.signinRedirect({ state: window.location.pathname + window.location.search });
        }
      };

      client.events.addAccessTokenExpiring(renewTokenSilently);
      client.events.addAccessTokenExpired(renewTokenSilently);

      return client;
    };

    const initAuth = async () => {
      const client = createClient();
      setAuthClient(client);

      if (window.location.search.includes('code=')) {
        const response = await client.signinRedirectCallback();
        onRedirectCallback(response.state);
      }

      const authUser = await client.getUser();

      if (authUser) {
        setIsAuthenticated(true);
        setUser(authUser);
        setAuthorizationHeader(authUser.access_token);
        setApplicationInsightsUser(authUser.profile.sub);
      }

      setIsLoading(false);
    };

    initAuth();
    // eslint-disable-next-line
  }, []);

  if (!authClient) return null;

  return (
    <AuthContext.Provider
      value={{
        isLoading,
        isAuthenticated,
        user,
        loginWithRedirect: () =>
          authClient.signinRedirect({ state: window.location.pathname + window.location.search }),
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
