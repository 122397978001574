import React, { useState, useEffect } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classNames from 'classnames';

import useDashboardTranslation from 'hooks/useDashboardTranslation';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import ScrollableContent from 'components/ScrollableContent/ScrollableContent';

import './BidderInfo.scss';
import useBidderDetails from '../hooks/useBidderDetails';
import BidderInfoDetailsTab from './BidderInfoDetailsTab';
import BidderInfoAuctionActivityTab from './BidderInfoAuctionActivityTab';
import BidderInfoHistoryTab from './BidderInfoHistoryTab';
import BidderStatusText from './BidderStatusText';

enum BidderInfoTab {
  Details,
  AuctionActivity,
  History,
}

interface BidderInfoProps {
  bidderId: string | undefined;
  timestamp?: number;
  maxHeight?: number;
}

const BidderInfo: React.FC<BidderInfoProps> = ({ bidderId, timestamp, maxHeight }) => {
  const { t, tCommon } = useDashboardTranslation();
  const { data: bidder, isLoading } = useBidderDetails(bidderId, timestamp);
  const [activeTab, setActiveTab] = useState(BidderInfoTab.Details);

  useEffect(() => {
    setActiveTab(BidderInfoTab.Details);
  }, [bidder]);

  if (isLoading) return <LoadingIndicator />;
  if (!bidder) return <p>{tCommon('errorMessages.genericErrorMessage')}</p>;

  return (
    <div className="bidder-info">
      <div className="side-panel-heading">
        {bidder.customerRef}
        {bidder.paddleNumber && (
          <>
            <br />
            {t('bidderInfo.paddle')} {bidder.paddleNumber}
          </>
        )}
      </div>
      <ScrollableContent maxHeight={maxHeight}>
        <div className="bidder-info-content">
          <div className="bidder-info-summary">
            <h5>
              {bidder.title} {bidder.firstName} {bidder.lastName}
            </h5>
            <div>
              <div className="mb-1">{bidder.companyName}</div>
              <BidderStatusText bidder={bidder} />
              {bidder.banned && <div className="text-danger">{t('bidderInfo.banned')}</div>}
            </div>
          </div>
          <Nav tabs className="mb-3 pt-1">
            <NavItem>
              <NavLink
                className={classNames({ active: activeTab === BidderInfoTab.Details })}
                onClick={() => setActiveTab(BidderInfoTab.Details)}
              >
                {t('bidderInfo.details')}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classNames({ active: activeTab === BidderInfoTab.AuctionActivity })}
                onClick={() => setActiveTab(BidderInfoTab.AuctionActivity)}
              >
                {t('bidderInfo.auctionActivity')}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classNames({ active: activeTab === BidderInfoTab.History })}
                onClick={() => setActiveTab(BidderInfoTab.History)}
              >
                {t('bidderInfo.history')}
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab} className="w-100">
            <TabPane tabId={BidderInfoTab.Details}>
              {activeTab === BidderInfoTab.Details && <BidderInfoDetailsTab bidder={bidder} />}
            </TabPane>
            <TabPane tabId={BidderInfoTab.AuctionActivity}>
              {activeTab === BidderInfoTab.AuctionActivity && <BidderInfoAuctionActivityTab bidderId={bidderId} />}
            </TabPane>
            <TabPane tabId={BidderInfoTab.History}>
              {activeTab === BidderInfoTab.History && <BidderInfoHistoryTab customerId={bidder.customerId} />}
            </TabPane>
          </TabContent>
        </div>
      </ScrollableContent>
    </div>
  );
};

export default BidderInfo;
