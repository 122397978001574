import { usePaginatedQuery, queryCache } from 'react-query';

import { shortPollingTimespan } from 'config/api';
import { useAppContext } from 'contexts/AppContextProvider';
import { Lot, LotQueryResponse } from 'models/lot';
import { fetchLots } from 'api';

import { LotFiltersDefinition } from '../models/lotFilters';

export enum LotSortOption {
  NumberAsc = 'NumberAsc',
  NumberDesc = 'NumberDesc',
  TimeLeftAsc = 'TimeLeftAsc',
  TimeLeftDesc = 'TimeLeftDesc',
  HammerPriceAsc = 'HammerPriceAsc',
  HammerPriceDesc = 'HammerPriceDesc',
  MaxBidAsc = 'MaxBidAsc',
  MaxBidDesc = 'MaxBidDesc',
  BidsAsc = 'BidsAsc',
  BidsDesc = 'BidsDesc',
  WatchesAsc = 'WatchesAsc',
  WatchesDesc = 'WatchesDesc',
}

interface UseLotOptions {
  pageNumber: number;
  sortBy: LotSortOption;
  filters?: LotFiltersDefinition;
}

const useLots = (auctionRef: string | undefined, { pageNumber, sortBy, filters }: UseLotOptions) => {
  const { lotUpdates, addLotUpdate } = useAppContext();

  const dataWithUpdates = (data: LotQueryResponse | undefined) => {
    if (data == null) return data;
    return { ...data, docs: data.docs.map(x => ({ ...x, ...lotUpdates[x.lotId] })) };
  };

  const clearTerminatedUpdates = (newData: LotQueryResponse, oldData?: LotQueryResponse) => {
    const updatedLotIds = Object.keys(lotUpdates);
    const toDictionary = (lots: Lot[]): { [lotId: string]: Partial<Lot> } =>
      lots.filter(x => updatedLotIds.includes(x.lotId)).reduce((acc, lot) => ({ ...acc, [lot.lotId]: lot }), {});
    const newLots = toDictionary(newData.docs);
    const oldLots = oldData ? toDictionary(oldData.docs) : {};

    Object.entries(lotUpdates).forEach(([lotId, update]) => {
      Object.keys(update).forEach(key => {
        const lotKey = key as keyof Lot;
        if (!newLots[lotId]) return;
        if (newLots[lotId][lotKey] === oldLots[lotId]?.[lotKey]) return;

        addLotUpdate(lotId, { [lotKey]: undefined });
      });
    });
  };

  const query = usePaginatedQuery(
    ['lots', auctionRef, pageNumber, sortBy, filters],
    async () => {
      if (!auctionRef) return undefined;

      const previousData = queryCache.getQueryData<LotQueryResponse>(['lots', auctionRef, pageNumber, sortBy, filters]);

      const options = { pageNumber, pageSize: 50, sortBy, ...filters };
      const { data, status } = await fetchLots(auctionRef, options, previousData?.dataTimestamp);

      if (status === 304) return previousData;

      clearTerminatedUpdates(data, previousData);

      return data;
    },
    {
      refetchInterval: shortPollingTimespan,
      refetchIntervalInBackground: true,
      cacheTime: 0,
    },
  );

  return { ...query, resolvedData: dataWithUpdates(query.resolvedData) };
};

export default useLots;
