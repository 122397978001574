import React, { useState } from 'react';
import classNames from 'classnames';

import AuctionDetailsInfo from './AuctionDetailsInfo';
import AuctionStats from './AuctionStats';
import AuctionDetailsToggle from './AuctionDetailsToggle';

import './AuctionDetails.scss';

export default React.forwardRef<HTMLDivElement>(function AuctionDetails(_, forwardedRef) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div ref={forwardedRef} className={classNames('auction-details', { open: isOpen })}>
      <AuctionDetailsInfo />
      <AuctionDetailsToggle isOpen={isOpen} onClick={setIsOpen} />
      {isOpen && <AuctionStats />}
    </div>
  );
});
