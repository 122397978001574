import React, { useState } from 'react';
import Toggle from 'react-toggle';

import 'react-toggle/style.css';
import './LotFilters.scss';

import useDashboardTranslation from 'hooks/useDashboardTranslation';
import { useAppContext } from 'contexts/AppContextProvider';

import {
  LotFiltersDefinition,
  LotTextFilterOption,
  ReserveFilterOption,
  TextFilterDefinition,
} from '../models/lotFilters';
import TextFilter from './TextFilter';
import ReserveFilter from './ReserveFilter';

const createFiltersDefinition = (
  filterBy: LotTextFilterOption,
  searchTerm: string,
  activeOnly: boolean,
  reserveFilter: ReserveFilterOption,
): LotFiltersDefinition => {
  const trimmedSearchTerm = searchTerm.trim();

  return {
    textFilterType: trimmedSearchTerm ? filterBy : undefined,
    textFilter: trimmedSearchTerm || undefined,
    activeOnly: activeOnly || undefined,
    reserveFilter: reserveFilter === ReserveFilterOption.All ? undefined : reserveFilter,
  };
};

interface LotFiltersProps {
  initialFilters: LotFiltersDefinition;
  onFiltersChange: (filters: LotFiltersDefinition) => void;
}

const LotFilters: React.FC<LotFiltersProps> = ({ initialFilters, onFiltersChange }) => {
  const { t } = useDashboardTranslation();
  const { auctionDetails } = useAppContext();
  const [activeOnly, setActiveOnly] = useState(initialFilters.activeOnly ?? false);
  const [reserveFilter, setReserveFilter] = useState(ReserveFilterOption.All);
  const [appliedTextFilter, setAppliedTextFilter] = useState({
    textFilterType: initialFilters.textFilterType ?? LotTextFilterOption.Number,
    textFilter: initialFilters.textFilter ?? '',
  });

  const updateFilters = (newFilters: LotFiltersDefinition) => {
    const filters = createFiltersDefinition(
      newFilters.textFilterType ?? appliedTextFilter.textFilterType,
      newFilters.textFilter ?? appliedTextFilter.textFilter,
      newFilters.activeOnly ?? activeOnly,
      newFilters.reserveFilter ?? reserveFilter,
    );

    onFiltersChange(filters);
  };

  const handleFilterButtonClick = (filters: TextFilterDefinition) => {
    updateFilters(filters);
    setAppliedTextFilter(filters);
  };

  const handleActiveOnlyChange = () => {
    const newActiveOnly = !activeOnly;
    setActiveOnly(newActiveOnly);
    updateFilters({ activeOnly: newActiveOnly });
  };

  const handleReserveFilterChange = (newReserveFilter: ReserveFilterOption) => {
    setReserveFilter(newReserveFilter);
    updateFilters({ reserveFilter: newReserveFilter });
  };

  const handleResetTextFilter = () => {
    updateFilters({ textFilter: '' });
    setAppliedTextFilter({ ...appliedTextFilter, textFilter: '' });
  };

  return (
    <div className="filters-container container">
      <div className="d-flex justify-content-between mb-2">
        <TextFilter initialFilters={initialFilters} onFilterChange={handleFilterButtonClick} />
        <div className="d-flex">
          {auctionDetails.showReserves && (
            <ReserveFilter currentFilter={reserveFilter} onClick={handleReserveFilterChange} />
          )}
          <div className="closed-lots-toggle-container">
            <span id="closed-lots-toggle-label">{t('lotList.activeOnly')}</span>
            <Toggle
              id="closed-lots-toggle"
              icons={false}
              aria-labelledby="closed-lots-toggle-label"
              checked={activeOnly}
              onChange={handleActiveOnlyChange}
            />
          </div>
        </div>
      </div>

      <p className="mb-0" style={{ visibility: appliedTextFilter.textFilter.trim() ? 'visible' : 'hidden' }}>
        {t('lotList.currentlyAppliedFilter')} <b>{appliedTextFilter.textFilter}</b> (
        {t(`lotList.filterOption.${appliedTextFilter.textFilterType}`)}){' '}
        <button type="button" className="reset-filters-button" onClick={handleResetTextFilter}>
          {t('lotList.reset')}
        </button>
      </p>
    </div>
  );
};

export default LotFilters;
