import React from 'react';
import { Row, Col } from 'reactstrap';

import './LotHeader.scss';

import useDashboardTranslation from 'hooks/useDashboardTranslation';
import { useAppContext } from 'contexts/AppContextProvider';

import { LotSortOption } from '../hooks/useLots';
import SortButton from './SortButton';

interface LotHeaderProps {
  sortBy: LotSortOption;
  onSortingChange: (sortOption: LotSortOption) => void;
}

const LotHeader: React.FC<LotHeaderProps> = ({ sortBy, onSortingChange }) => {
  const { t } = useDashboardTranslation();
  const {
    auctionDetails: { showMaxBids },
  } = useAppContext();

  return (
    <Row className="lot-header">
      <Col xs="3" md="2">
        {t('lotList.lotNumber')}
        <SortButton
          activeSortBy={sortBy}
          ascSortOption={LotSortOption.NumberAsc}
          descSortOption={LotSortOption.NumberDesc}
          defaultSortOption={LotSortOption.NumberAsc}
          onClick={onSortingChange}
        />
      </Col>
      <Col xs="3" md="2">
        {t('lotList.title')}
      </Col>
      <Col xs="2">
        {t('lotList.timeRemaining')}
        <SortButton
          activeSortBy={sortBy}
          ascSortOption={LotSortOption.TimeLeftAsc}
          descSortOption={LotSortOption.TimeLeftDesc}
          defaultSortOption={LotSortOption.TimeLeftAsc}
          onClick={onSortingChange}
        />
      </Col>
      <Col xs="2">
        {t('lotList.hammer')}
        <SortButton
          activeSortBy={sortBy}
          ascSortOption={LotSortOption.HammerPriceAsc}
          descSortOption={LotSortOption.HammerPriceDesc}
          defaultSortOption={LotSortOption.HammerPriceDesc}
          onClick={onSortingChange}
        />
      </Col>
      <Col xs="2">
        {showMaxBids ? (
          <>
            {t('lotList.maxBid')}
            <SortButton
              activeSortBy={sortBy}
              ascSortOption={LotSortOption.MaxBidAsc}
              descSortOption={LotSortOption.MaxBidDesc}
              defaultSortOption={LotSortOption.MaxBidDesc}
              onClick={onSortingChange}
            />
          </>
        ) : (
          <>{t('lotList.leadingBidder')}</>
        )}
      </Col>
      <Col xs="1" className="d-none d-md-block">
        {t('lotList.bids')}
        <SortButton
          activeSortBy={sortBy}
          ascSortOption={LotSortOption.BidsAsc}
          descSortOption={LotSortOption.BidsDesc}
          defaultSortOption={LotSortOption.BidsDesc}
          onClick={onSortingChange}
        />
      </Col>
      <Col xs="1" className="d-none d-md-block">
        {t('lotList.watches')}
        <SortButton
          activeSortBy={sortBy}
          ascSortOption={LotSortOption.WatchesAsc}
          descSortOption={LotSortOption.WatchesDesc}
          defaultSortOption={LotSortOption.WatchesDesc}
          onClick={onSortingChange}
        />
      </Col>
    </Row>
  );
};

export default LotHeader;
