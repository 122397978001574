import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import { useFormContext, useWatch } from 'react-hook-form';

import useDashboardTranslation from 'hooks/useDashboardTranslation';
import { formatCurrency } from 'utils/number';
import { capitalizeFirstLetter } from 'utils/string';
import { useAppContext } from 'contexts/AppContextProvider';

interface EditLotNumericValueConfirmationModalProps {
  isOpen: boolean;
  lotNumber: string;
  property: string;
  onClose: () => void;
  onConfirm: () => Promise<void>;
}

const EditLotNumericValueConfirmationModal: React.FC<EditLotNumericValueConfirmationModalProps> = ({
  isOpen,
  lotNumber,
  property,
  onClose,
  onConfirm,
}) => {
  const { t, tCommon } = useDashboardTranslation();
  const { auctionDetails } = useAppContext();
  const { control } = useFormContext();
  const value = useWatch({ control, name: 'value', defaultValue: '' });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleConfirm = async () => {
    setIsSubmitting(true);
    await onConfirm();
    setIsSubmitting(false);
  };

  return (
    <Modal isOpen={isOpen} toggle={onClose}>
      <ModalHeader toggle={onClose}>
        {t(`editLot.confirmationModalEditLot${capitalizeFirstLetter(property)}.title`)}
      </ModalHeader>
      <ModalBody>
        {t(`editLot.confirmationModalEditLot${capitalizeFirstLetter(property)}.description`, {
          lotNumber,
          value: formatCurrency(auctionDetails.currencyCode)(parseFloat(value), false),
        })}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" disabled={isSubmitting} onClick={handleConfirm}>
          {isSubmitting ? <Spinner size="sm" className="mx-3" /> : tCommon('confirm')}
        </Button>{' '}
        <Button color="secondary" onClick={onClose}>
          {tCommon('cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default EditLotNumericValueConfirmationModal;
