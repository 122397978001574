import React, { useState, SyntheticEvent } from 'react';
import {
  InputGroup,
  InputGroupAddon,
  Button,
  Input,
  InputGroupText,
  InputGroupButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import useDashboardTranslation from 'hooks/useDashboardTranslation';

import { LotFiltersDefinition, LotTextFilterOption, TextFilterDefinition } from '../models/lotFilters';

interface TextFilterProps {
  initialFilters: LotFiltersDefinition;
  onFilterChange: (filters: TextFilterDefinition) => void;
}

const TextFilter: React.FC<TextFilterProps> = ({ initialFilters, onFilterChange }) => {
  const { t } = useDashboardTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [filterBy, setFilterBy] = useState(initialFilters.textFilterType ?? LotTextFilterOption.Number);
  const [searchTerm, setSearchTerm] = useState(initialFilters.textFilter ?? '');

  const handleFilterByChange = (filterOption: string) => {
    const filterOptionKey = filterOption as keyof typeof LotTextFilterOption;
    setFilterBy(LotTextFilterOption[filterOptionKey]);
  };

  const onSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    onFilterChange({ textFilterType: filterBy, textFilter: searchTerm });
  };

  return (
    <form className="text-filter" onSubmit={onSubmit}>
      <InputGroup>
        <InputGroupAddon addonType="prepend">
          <InputGroupText>
            <span className="icon icon-search" />
          </InputGroupText>
        </InputGroupAddon>
        <InputGroupButtonDropdown addonType="prepend" isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
          <DropdownToggle caret>{t(`lotList.filterOption.${filterBy}`)}</DropdownToggle>
          <DropdownMenu>
            {Object.keys(LotTextFilterOption).map(filterOption => (
              <DropdownItem key={filterOption} onClick={() => handleFilterByChange(filterOption)}>
                {t(`lotList.filterOption.${filterOption}`)}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </InputGroupButtonDropdown>
        <Input
          value={searchTerm}
          aria-label={t('lotList.filterInputLabel')}
          onChange={e => setSearchTerm(e.target.value)}
        />
        <InputGroupAddon addonType="append">
          <Button color="primary" type="submit">
            {t('lotList.filter')}
          </Button>
        </InputGroupAddon>
      </InputGroup>
    </form>
  );
};

export default TextFilter;
