import React from 'react';

import useDashboardTranslation from 'hooks/useDashboardTranslation';

import './AuctionDetailsToggle.scss';

interface AuctionDetailsToggleProps {
  isOpen: boolean;
  onClick: (isOpen: boolean) => void;
}

const AuctionDetailsToggle: React.FC<AuctionDetailsToggleProps> = ({ isOpen, onClick }) => {
  const { tCommon } = useDashboardTranslation();

  return (
    <>
      {isOpen && <div className="divider" />}
      <button type="button" className="btn-toggle" onClick={() => onClick(!isOpen)}>
        {isOpen ? (
          <>
            <span className="icon-double_arrow_up" />
            <small>{tCommon('less')}</small>
          </>
        ) : (
          <>
            <span className="icon-double_arrow_down" />
            <small>{tCommon('more')}</small>
          </>
        )}
      </button>
    </>
  );
};

export default AuctionDetailsToggle;
