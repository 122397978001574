import { useQuery } from 'react-query';

import { useAppContext } from 'contexts/AppContextProvider';
import { fetchBidderDetails } from 'api';

const useBidderDetails = (bidderId: string | undefined, dataTimestamp?: number) => {
  const { auctionDetails } = useAppContext();
  return useQuery(['bidderDetails', auctionDetails.ref, bidderId, dataTimestamp], async () => {
    if (!bidderId) return undefined;

    const { data } = await fetchBidderDetails(auctionDetails.ref, bidderId);
    return data;
  });
};

export default useBidderDetails;
