import { useRef, useEffect } from 'react';

const useScrollTopOnPageChange = (page?: number) => {
  const scrollRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (!scrollRef.current) return;

    scrollRef.current.scrollTop = 0;
  }, [page]);

  return scrollRef;
};

export default useScrollTopOnPageChange;
