import { useQuery } from 'react-query';

import { fetchActiveBiddersCount } from 'api';

const useActiveBiddersCount = (auctionRef: string) => {
  return useQuery('activeBiddersCount', async () => {
    const { data } = await fetchActiveBiddersCount(auctionRef);

    return data;
  });
};

export default useActiveBiddersCount;
