import React, { useState } from 'react';
import classNames from 'classnames';
import { Trans } from 'react-i18next';
import { Button, PopoverHeader, PopoverBody, Popover, Spinner } from 'reactstrap';
import { queryCache, useMutation } from 'react-query';

import useDashboardTranslation from 'hooks/useDashboardTranslation';
import { PendingBidder } from 'models/pendingBidder';
import { useAppContext } from 'contexts/AppContextProvider';
import { approveBidder } from 'api';

import './PendingBiddersModal.scss';

interface PendingBiddersTableRowProps {
  bidder: PendingBidder;
  isActive: boolean;
  onBidderDetailsClick: () => void;
}

const PendingBiddersTableRow: React.FC<PendingBiddersTableRowProps> = ({ bidder, isActive, onBidderDetailsClick }) => {
  const { t, tCommon } = useDashboardTranslation();
  const { auctionDetails } = useAppContext();
  const [isApproveConfirmationOpen, setIsApproveConfirmationOpen] = useState(false);
  const [approve, { isLoading, isError, data, reset }] = useMutation(
    () => approveBidder(auctionDetails.ref, bidderId),
    {
      onSuccess: () => {
        setIsApproveConfirmationOpen(false);
      },
      onSettled: () => {
        queryCache.invalidateQueries('pendingBidders');
        queryCache.invalidateQueries('bidderDetails');
      },
    },
  );

  const toggleApproveConfirmation = () => {
    reset();
    setIsApproveConfirmationOpen(x => !x);
  };

  const { paddleNumber, firstName, lastName, companyName, bidderId, approveTriggered } = bidder;

  return (
    <tr onClick={onBidderDetailsClick} className={classNames('bider-row', { active: isActive })}>
      <td>{paddleNumber}</td>
      <td>
        {firstName} {lastName}
      </td>
      <td>{companyName}</td>
      <td>
        {approveTriggered ? (
          <span className="text-success">{t('auctionDetails.pendingBiddersModal.approving')}</span>
        ) : (
          <span className="text-warning">{t('auctionDetails.pendingBiddersModal.pending')}</span>
        )}
      </td>
      <td className="actions">
        {!approveTriggered && (
          <>
            <button
              id={`approve-${bidderId}-button`}
              type="button"
              className="btn btn-primary btn-sm"
              title={t('auctionDetails.pendingBiddersModal.approveBidderTitle')}
              onClick={e => e.stopPropagation()}
            >
              <span>{t('auctionDetails.pendingBiddersModal.approve')}</span>
            </button>
            <Popover
              isOpen={isApproveConfirmationOpen}
              toggle={toggleApproveConfirmation}
              trigger="legacy"
              placement="bottom"
              target={`approve-${bidderId}-button`}
            >
              <PopoverHeader>{t('auctionDetails.pendingBiddersModal.approveBidderTitle')}</PopoverHeader>
              <PopoverBody>
                <p>
                  <Trans
                    t={t}
                    i18nKey="auctionDetails.pendingBiddersModal.approveBidderDescription"
                    values={{ firstName, lastName, companyName: companyName ? ` (${companyName})` : '' }}
                  />
                </p>
                {Boolean(data?.data?.length) && (
                  <p className="text-danger">
                    <Trans
                      t={t}
                      i18nKey="auctionDetails.pendingBiddersModal.linkedAuctionsError"
                      values={{ firstName, lastName, auctions: data?.data?.join(', ') }}
                    />
                  </p>
                )}
                {isError && <p className="text-danger">{tCommon('errorMessages.genericErrorMessage')}</p>}
                <div className="text-right">
                  <Button
                    color="primary"
                    disabled={isLoading || approveTriggered}
                    onClick={e => {
                      approve();
                      e.stopPropagation();
                    }}
                  >
                    {isLoading ? <Spinner size="sm" /> : tCommon('confirm')}
                  </Button>
                </div>
              </PopoverBody>
            </Popover>
          </>
        )}
      </td>
    </tr>
  );
};

export default PendingBiddersTableRow;
