import React, { createContext, useContext, useState } from 'react';
import * as R from 'ramda';

import { AuctionDetails } from 'models/auctionDetails';
import { Lot } from 'models/lot';
import useAuctionDetails from 'hooks/useAuctionDetails';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import ErrorComponent from 'components/Error/Error';

interface AppContext {
  auctionDetails: AuctionDetails;
  lotUpdates: { [lotId: string]: Partial<Lot> };
  addLotUpdate: (lotId: string, update: Partial<Lot>) => void;
}

const AppContext = createContext<AppContext | undefined>(undefined);

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (context === undefined) throw new Error('useAppContext must be used within a <AppContextProvider>');

  return context;
};

const filterOutUndefined = <T extends {}>(obj: T) => R.pickBy<T, T>(R.compose(R.not, R.isNil), obj);

interface AppContextProviderProps {
  auctionRef: string;
}

const AppContextProvider: React.FC<AppContextProviderProps> = ({ children, auctionRef }) => {
  const { isLoading, errorMessage, auctionDetails } = useAuctionDetails(auctionRef);
  const [lotUpdates, setLotUpdates] = useState<{ [lotId: string]: Partial<Lot> }>({});

  if (isLoading) return <LoadingIndicator />;

  if (!auctionDetails) return <ErrorComponent errorMessage={errorMessage} />;

  const addLotUpdate = (lotId: string, lotUpdate: Partial<Lot>) => {
    setLotUpdates(updates => ({
      ...updates,
      [lotId]: updates[lotId]
        ? filterOutUndefined({ ...updates[lotId], ...lotUpdate })
        : filterOutUndefined({ ...lotUpdate }),
    }));
  };

  return <AppContext.Provider value={{ auctionDetails, lotUpdates, addLotUpdate }}>{children}</AppContext.Provider>;
};

export default AppContextProvider;
