import { useTranslation, UseTranslationOptions } from 'react-i18next';

import { I18nNamespace } from 'models/enums';

const useDashboardTranslation = (options?: UseTranslationOptions) => {
  const { t } = useTranslation([I18nNamespace.Dashboard, I18nNamespace.Common], options);
  const tCommon = (key: string, opts?: object) => t(`${I18nNamespace.Common}:${key}`, opts);

  return {
    t,
    tCommon,
  };
};

export default useDashboardTranslation;
