import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useServerTime from 'hooks/useServerTime';
import { useAuth } from 'contexts/AuthProvider';
import AppContextProvider from 'contexts/AppContextProvider';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import Error from 'components/Error/Error';
import NavMenu from 'components/NavMenu/NavMenu';
import Dashboard from 'pages/Dashboard/Dashboard';

const App: React.FC = () => {
  const { t } = useTranslation();
  const { auctionRef } = useParams();
  const { isLoading: isAuthLoading, isAuthenticated, loginWithRedirect } = useAuth();
  const { isLoading: isServerTimeLoading } = useServerTime();

  useEffect(() => {
    if (isAuthLoading || isAuthenticated) return;

    loginWithRedirect();
  }, [isAuthLoading, isAuthenticated, loginWithRedirect]);

  if (isAuthLoading || isServerTimeLoading) return <LoadingIndicator />;
  if (!isAuthenticated) return null;
  if (!auctionRef) return <Error errorMessage={t('errorMessages.missingAuctionRef')} />;

  return (
    <AppContextProvider auctionRef={auctionRef}>
      <NavMenu />
      <Dashboard />
    </AppContextProvider>
  );
};

export default App;
