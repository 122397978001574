import React from 'react';

import useDashboardTranslation from 'hooks/useDashboardTranslation';
import { BidderDetails } from 'models/bidderDetails';

interface BidderStatusTextProps {
  bidder: BidderDetails;
}

const BidderStatusText: React.FC<BidderStatusTextProps> = ({ bidder }) => {
  const { t } = useDashboardTranslation();

  if (bidder.declined) return <div className="text-danger">{t('bidderInfo.declined')}</div>;
  if (bidder.approveTriggered) return <div className="text-success">{t('bidderInfo.approving')}</div>;
  if (bidder.pending) return <div className="text-warning">{t('bidderInfo.pending')}</div>;

  return <div className="text-success">{t('bidderInfo.approved')}</div>;
};

export default BidderStatusText;
