export enum PlatformCode {
  AuctionTechnologyGroup = 'ATG',
  BidSpotterCom = 'BSC',
  BidSPotterCoUk = 'BS',
  IBidder = 'IB',
  TheSaleroom = 'SR',
}

export enum I18nNamespace {
  Common = 'common',
  Dashboard = 'dashboard',
}
