import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { I18nNamespace } from 'models/enums';
import { BidderHistory } from 'models/bidderHistory';
import { useAppContext } from 'contexts/AppContextProvider';
import { fetchCustomerHistory } from 'api';

const useBidderHistory = (customerId: string) => {
  const { t } = useTranslation(I18nNamespace.Common);
  const { auctionDetails } = useAppContext();
  const [bidderHistory, setBidderHistory] = useState<BidderHistory>();
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (!customerId) return;

    const resetState = () => {
      setBidderHistory(undefined);
      setIsLoading(true);
      setErrorMessage('');
    };

    resetState();
    fetchCustomerHistory(auctionDetails.clientId, auctionDetails.ref, customerId)
      .then(res => setBidderHistory(res.data))
      .catch(() => setErrorMessage(t('errorMessages.genericErrorMessage')))
      .finally(() => setIsLoading(false));
  }, [customerId, auctionDetails.clientId, auctionDetails.ref, t]);

  return { bidderHistory, isLoading, errorMessage };
};

export default useBidderHistory;
