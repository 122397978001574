import React from 'react';

import useDashboardTranslation from 'hooks/useDashboardTranslation';
import { useAppContext } from 'contexts/AppContextProvider';
import { formatCurrency } from 'utils/number';
import { Lot } from 'models/lot';
import { updateLotReserve } from 'api';

import EditLotNumericValue from './EditLotNumericValue';

interface EditLotReserveProps {
  lot: Lot;
  isUpdating: boolean;
  onUpdate: (increment: number) => void;
}

const EditLotReserve: React.FC<EditLotReserveProps> = ({ lot, isUpdating, onUpdate }) => {
  const { t } = useDashboardTranslation();
  const { auctionDetails } = useAppContext();

  const handleUpdate = async (reserve: number) => {
    await updateLotReserve(auctionDetails.ref, lot.lotId, reserve);
    onUpdate(reserve);
  };

  const renderCurrentValue = () =>
    lot.reservePrice ? (
      <>
        {t('editLot.lotReserve')}{' '}
        <b className="font-weight-semi-bold">{formatCurrency(auctionDetails.currencyCode)(lot.reservePrice)}</b>
      </>
    ) : null;

  return (
    <EditLotNumericValue
      lot={lot}
      property="reservePrice"
      isUpdating={isUpdating}
      currentValue={lot.reservePrice}
      validate={(val: string) =>
        !lot.openingPrice ||
        parseFloat(val) === 0 ||
        parseFloat(val) >= lot.openingPrice ||
        t<string>('editLot.validation.reserveGreaterThanOpeningPrice')
      }
      renderCurrentValue={renderCurrentValue}
      onUpdate={handleUpdate}
    />
  );
};

export default EditLotReserve;
