import React from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'reactstrap';

import { I18nNamespace } from 'models/enums';

const LoadingIndicator: React.FC = () => {
  const { t } = useTranslation(I18nNamespace.Common);

  return (
    <div className="d-flex flex-column justify-content-center align-items-center h-100 w-100">
      <Spinner className="mb-2"> </Spinner>
      {t('loading')}
    </div>
  );
};

export default LoadingIndicator;
