import React from 'react';
import SimpleBar from 'simplebar-react';
import { useMeasure } from 'react-use';

interface ScrollableContentProps {
  maxHeight?: number;
}

const ScrollableContent: React.FC<ScrollableContentProps> = ({ maxHeight, children }) => {
  const [contentRef, { height: contentHeight }] = useMeasure<HTMLDivElement>();

  return (
    <div style={{ height: maxHeight && contentHeight > maxHeight ? maxHeight : 'auto' }}>
      <SimpleBar>
        <div ref={contentRef}>{children}</div>
      </SimpleBar>
    </div>
  );
};

export default ScrollableContent;
