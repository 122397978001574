import React from 'react';
import { useTransition, animated } from 'react-spring';
import SimpleBar from 'simplebar-react';

import 'simplebar/dist/simplebar.min.css';

import { Lot } from 'models/lot';
import { PaginatedResponse } from 'models/pagination';

import { SidePanelContent } from '../models/sidePanel';
import useScrollTopOnPageChange from '../hooks/useScrollTopOnPageChange';
import { LotSortOption } from '../hooks/useLots';
import LotHeader from './LotHeader';
import LotRow from './LotRow';

const rowHeight = 125;
const rowMargin = 14;

const useTransitionedLots = (lots: Lot[] | undefined, preventDelay: boolean) => {
  return useTransition(lots?.map((data: Lot, idx: number) => ({ ...data, y: (rowHeight + rowMargin) * idx })) ?? [], {
    key: (d: Lot) => d.lotId,
    from: { opacity: 0 },
    leave: { opacity: 0 },
    enter: ({ y }) => ({ y, opacity: 1 }),
    update: ({ y }) => ({ y, opacity: 1, delay: preventDelay ? undefined : 2000 }),
  });
};

interface LotListProps {
  lots: PaginatedResponse<Lot>;
  sortBy: LotSortOption;
  preventRowAnimations: boolean;
  isSidePanelOpen: boolean;
  onSortByChange: (sortOption: LotSortOption) => void;
  onSidePanelOpen: (content: SidePanelContent) => void;
}

const LotList: React.FC<LotListProps> = ({
  lots,
  sortBy,
  preventRowAnimations,
  isSidePanelOpen,
  onSortByChange,
  onSidePanelOpen,
}) => {
  const transition = useTransitionedLots(lots.docs, preventRowAnimations);
  const scrollRef = useScrollTopOnPageChange(lots.currentPage);

  return (
    <>
      <LotHeader sortBy={sortBy} onSortingChange={sortOption => onSortByChange(sortOption)} />
      <SimpleBar scrollableNodeProps={{ ref: scrollRef }}>
        <div style={{ height: lots.docs.length * (rowHeight + rowMargin) + rowMargin }}>
          {transition((style, item) => {
            const { y, ...rest } = style;

            return (
              <animated.div
                style={{
                  position: 'absolute',
                  width: '100%',
                  transform: y.to((x: number) => `translate3d(0, ${x}px, 0)`),
                  ...rest,
                }}
              >
                <LotRow
                  lot={item}
                  preventAnimations={preventRowAnimations}
                  narrowImage={isSidePanelOpen}
                  onSidePanelOpen={onSidePanelOpen}
                />
              </animated.div>
            );
          })}
        </div>
      </SimpleBar>
    </>
  );
};

export default LotList;
