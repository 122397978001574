import React from 'react';

import useDashboardTranslation from 'hooks/useDashboardTranslation';
import { useAppContext } from 'contexts/AppContextProvider';
import { formatCurrency } from 'utils/number';
import { Lot } from 'models/lot';
import { updateLotOpeningPrice } from 'api';

import EditLotNumericValue from './EditLotNumericValue';

interface EditLotOpeningPriceProps {
  lot: Lot;
  isUpdating: boolean;
  onUpdate: (increment: number) => void;
}

const EditLotOpeningPrice: React.FC<EditLotOpeningPriceProps> = ({ lot, isUpdating, onUpdate }) => {
  const { t } = useDashboardTranslation();
  const { auctionDetails } = useAppContext();

  const handleUpdate = async (openingPrice: number) => {
    await updateLotOpeningPrice(auctionDetails.ref, lot.lotId, openingPrice);
    onUpdate(openingPrice);
  };

  const renderCurrentValue = () =>
    lot.openingPrice ? (
      <>
        {t('editLot.lotOpeningPrice')}{' '}
        <b className="font-weight-semi-bold">{formatCurrency(auctionDetails.currencyCode)(lot.openingPrice)}</b>
      </>
    ) : null;

  return (
    <EditLotNumericValue
      lot={lot}
      property="openingPrice"
      isUpdating={isUpdating}
      currentValue={lot.openingPrice}
      validate={(val: string) =>
        !lot.reservePrice ||
        parseFloat(val) <= lot.reservePrice ||
        t<string>('editLot.validation.openingPriceLessThanReserve')
      }
      renderCurrentValue={renderCurrentValue}
      onUpdate={handleUpdate}
    />
  );
};

export default EditLotOpeningPrice;
