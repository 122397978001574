import React from 'react';

import useDashboardTranslation from 'hooks/useDashboardTranslation';
import { useAppContext } from 'contexts/AppContextProvider';
import { BidderDetails } from 'models/bidderDetails';
import { formatDateWithTimezone } from 'utils/date';

interface BidderInfoDetailsTabProps {
  bidder: BidderDetails;
}

const BidderInfoDetailsTab: React.FC<BidderInfoDetailsTabProps> = ({ bidder }) => {
  const { t } = useDashboardTranslation();
  const { auctionDetails } = useAppContext();

  return (
    <>
      <div className="bidder-details-line">
        <span className="icon-mail" title={t('bidderInfo.emailTitle')} />
        <span>
          <span className="text-break">{bidder.email} </span>
          {bidder.emailVerified ? (
            <span className="text-success">{t('bidderInfo.verified')}</span>
          ) : (
            <span className="text-danger">{t('bidderInfo.notVerified')}</span>
          )}
        </span>
      </div>
      <div className="bidder-details-line">
        <span className="icon-phone" title={t('bidderInfo.phoneNumberTitle')} />
        <span>
          <span className="text-break">{bidder.phoneNumber} </span>
          {bidder.phoneNumberVerified ? (
            <span className="text-success">{t('bidderInfo.verified')}</span>
          ) : (
            <span className="text-danger">{t('bidderInfo.notVerified')}</span>
          )}
        </span>
      </div>
      <div className="bidder-details-line align-items-start">
        <span className="icon-location" title={t('bidderInfo.locationTitle')} />
        <span>
          {bidder.addressLines.map((line: string) => (
            <span key={line}>
              {line}
              <br />
            </span>
          ))}
        </span>
      </div>
      <div className="pt-2 text-center text-muted">
        {t('bidderInfo.registered', {
          date: formatDateWithTimezone(bidder.auctionRegistrationDate, auctionDetails.timezone),
        })}
      </div>
    </>
  );
};

export default BidderInfoDetailsTab;
