import React from 'react';
import { useTranslation } from 'react-i18next';

interface ErrorProps {
  errorMessage: string;
}

const Error: React.FC<ErrorProps> = ({ errorMessage }) => {
  const { t } = useTranslation();

  return (
    <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
      <h1>{t('errorMessages.errorOccurred')}</h1>
      <p>{errorMessage}</p>
    </div>
  );
};

export default Error;
