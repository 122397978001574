import React from 'react';
import { useMeasure } from 'react-use';

import './SidePanel.scss';
import { SidePanelContent, SidePanelContentType } from '../models/sidePanel';
import BidderInfo from './BidderInfo';
import BidsHistory from './BidsHistory';
import EditLot from './EditLot';

interface SidePanelProps {
  content: SidePanelContent;
  onClose: () => void;
}

const SidePanel: React.FC<SidePanelProps> = ({ content, onClose }) => {
  const [ref, { height: containerHeight }] = useMeasure<HTMLDivElement>();

  return (
    <div className="side-panel" ref={ref}>
      <button type="button" className="close-button" onClick={onClose}>
        <span className="icon-clearclose" />
      </button>
      <div className="side-panel-content">
        {content.type === SidePanelContentType.BidderInfo && (
          <BidderInfo
            bidderId={content.lot.leadingBidderId}
            timestamp={content.timestamp}
            maxHeight={containerHeight}
          />
        )}
        {content.type === SidePanelContentType.BidsHistory && (
          <BidsHistory
            lotId={content.lot.lotId}
            lotNumber={content.lot.number}
            timestamp={content.timestamp}
            maxHeight={containerHeight}
          />
        )}
        {content.type === SidePanelContentType.EditLot && <EditLot lot={content.lot} maxHeight={containerHeight} />}
      </div>
    </div>
  );
};

export default SidePanel;
