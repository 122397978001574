import React from 'react';

import { useAppContext } from 'contexts/AppContextProvider';
import { formatCurrency } from 'utils/number';
import { formatDateWithTimezone } from 'utils/date';
import useDashboardTranslation from 'hooks/useDashboardTranslation';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import ScrollableContent from 'components/ScrollableContent/ScrollableContent';

import './BidsHistory.scss';
import useLotBidsHistory from '../hooks/useLotBidsHistory';

interface BidsHistoryProps {
  lotId?: string;
  lotNumber?: string;
  timestamp: number;
  maxHeight: number;
}

const BidsHistory: React.FC<BidsHistoryProps> = ({ lotId, lotNumber, timestamp, maxHeight }) => {
  const { t } = useDashboardTranslation();
  const {
    auctionDetails: { currencyCode, timezone },
  } = useAppContext();
  const { lotBidsHistory, isLoading, errorMessage } = useLotBidsHistory(lotId, timestamp);

  if (isLoading) return <LoadingIndicator />;
  if (!lotBidsHistory) return <p>{errorMessage}</p>;

  return (
    <div className="bids-history">
      <div className="side-panel-heading">{t('bidsHistory.lotNumber', { lotNumber })}</div>
      <ScrollableContent maxHeight={maxHeight}>
        <div className="bids-history-content">
          <h5 className="text-center">{t('bidsHistory.title')}</h5>
          <br />
          {lotBidsHistory.map(bid => (
            <div key={bid.bidderFirstName + bid.bidderLastName + bid.bidPlacedDateUtc} className="bids-history-item">
              <div className="d-flex justify-content-between">
                <span className="bid-amount">{formatCurrency(currencyCode)(bid.bidAmount)}</span>
                <i>
                  {bid.bidderFirstName} {bid.bidderLastName}
                </i>
              </div>
              {formatDateWithTimezone(bid.bidPlacedDateUtc, timezone)}
              <hr />
            </div>
          ))}
        </div>
      </ScrollableContent>
    </div>
  );
};

export default BidsHistory;
