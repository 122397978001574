import { useState, useEffect, useCallback, CSSProperties } from 'react';
import { TFunction } from 'i18next';
import moment from 'moment';
import 'moment-precise-range-plugin';

import { Lot } from 'models/lot';
import { useTranslation } from 'react-i18next';
import { I18nNamespace } from 'models/enums';
import { formatDateWithTimezone } from 'utils/date';
import { useAppContext } from 'contexts/AppContextProvider';

const getTimeFromLastBid = (t: TFunction, timezone: string) => (lastBidTimeUtc?: string) => {
  if (!lastBidTimeUtc) return undefined;

  const { years, months, days, hours, minutes, seconds } = moment.utc(lastBidTimeUtc).preciseDiff(moment.utc(), true);

  if (years || months || days) return formatDateWithTimezone(lastBidTimeUtc, timezone);
  if (hours) return t('hoursAgo', { count: hours });
  if (minutes > 0 && minutes < 10) return `${t('minutes', { count: minutes })} ${t('secondsAgo', { count: seconds })}`;
  if (minutes) return t('minutesAgo', { count: minutes });
  return t('secondsAgo', { count: seconds });
};

const getExtensionPeriodStyle = (minutes: number, autoExtensionPeriod?: number): CSSProperties =>
  autoExtensionPeriod && minutes < autoExtensionPeriod ? { color: 'darkorange' } : {};

const getTimeLeft = (t: TFunction) => (
  lotEndTimeUtc: string,
  autoExtensionPeriod?: number,
): { value: string; style: CSSProperties } | undefined => {
  const diff = moment.utc(lotEndTimeUtc).diff(moment.utc(), 'seconds');

  if (diff < -10) return undefined;

  const minutesDiff = diff / 60;
  const autoExtensionPeriodStyle = getExtensionPeriodStyle(minutesDiff, autoExtensionPeriod);
  const styledTimeLeft = (value: string, style = {}) => ({ value, style: { ...autoExtensionPeriodStyle, ...style } });
  const preciseDiff = moment.utc(lotEndTimeUtc).preciseDiff(moment.utc(), true);
  const { years, months, days, hours, minutes, seconds } = preciseDiff;

  if (years || months || days)
    return styledTimeLeft(t('days', { count: moment.utc(lotEndTimeUtc).diff(moment.utc(), 'days') }));

  if (hours) return styledTimeLeft(t('hours', { count: hours }));

  if (minutes > 0 && minutes < 10)
    return styledTimeLeft(`${t('minutes', { count: minutes })} ${t('seconds', { count: seconds })}`);

  if (minutes) return styledTimeLeft(t('minutes', { count: minutes }));

  if (seconds <= 10)
    return styledTimeLeft(t(`${I18nNamespace.Dashboard}:lotList.closing`), {
      color: 'red',
      fontStyle: 'italic',
      animation: 'pulse 2s infinite',
    });

  return styledTimeLeft(t('seconds', { count: seconds }), { color: 'red' });
};

const useLotTimers = (lot: Lot) => {
  const { t } = useTranslation([I18nNamespace.Common, I18nNamespace.Dashboard]);
  const {
    auctionDetails: { timezone, autoExtensionPeriod },
  } = useAppContext();
  const calculateTimeLeft = useCallback(getTimeLeft(t), [t]);
  const calculateTimeFromLastBid = useCallback(getTimeFromLastBid(t, timezone), [t, timezone]);
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(lot.endTimeUtc, autoExtensionPeriod));
  const [timeFromLastBid, setTimeFromLastBid] = useState(calculateTimeFromLastBid(lot.maxBidPlacedDateUtc));

  useEffect(() => {
    const refreshTimers = () => {
      setTimeLeft(calculateTimeLeft(lot.endTimeUtc, autoExtensionPeriod));
      setTimeFromLastBid(calculateTimeFromLastBid(lot.maxBidPlacedDateUtc));
    };

    refreshTimers();
    const intervalId = setInterval(refreshTimers, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [lot, calculateTimeLeft, calculateTimeFromLastBid, autoExtensionPeriod]);

  return { timeLeft, timeFromLastBid };
};

export default useLotTimers;
