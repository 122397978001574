import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { I18nNamespace } from 'models/enums';
import { BidderSaleActivity } from 'models/bidderSaleActivity';
import { useAppContext } from 'contexts/AppContextProvider';
import { fetchBidderSaleActivity } from 'api';

const useBidderSaleActivity = (bidderId?: string) => {
  const { t } = useTranslation(I18nNamespace.Common);
  const { auctionDetails } = useAppContext();
  const [saleActivity, setSaleActivity] = useState<BidderSaleActivity>();
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (!bidderId) return;

    const resetState = () => {
      setSaleActivity(undefined);
      setIsLoading(true);
      setErrorMessage('');
    };

    resetState();
    fetchBidderSaleActivity(auctionDetails.ref, bidderId)
      .then(res => setSaleActivity(res.data))
      .catch(() => setErrorMessage(t('errorMessages.genericErrorMessage')))
      .finally(() => setIsLoading(false));
  }, [bidderId, auctionDetails.ref, t]);

  return { saleActivity, isLoading, errorMessage };
};

export default useBidderSaleActivity;
