import React from 'react';
import { Table } from 'reactstrap';

import { AuctionDetails, TotalMaxBids } from 'models/auctionDetails';
import useDashboardTranslation from 'hooks/useDashboardTranslation';
import { formatCurrency } from 'utils/number';

import ReserveBreakdown from './ReserveBreakdown';

const createTotalsRow = (totalHammerStats: TotalMaxBids[], label: string) => {
  return totalHammerStats.reduce((acc, item) => ({
    platform: label,
    allLots: acc.allLots + item.allLots,
    allLotsReserveMet: acc.allLotsReserveMet + item.allLotsReserveMet,
    allLotsReserveNotMet: acc.allLotsReserveNotMet + item.allLotsReserveNotMet,
    activeLots: acc.activeLots + item.activeLots,
    activeLotsReserveMet: acc.activeLotsReserveMet + item.activeLotsReserveMet,
    activeLotsReserveNotMet: acc.activeLotsReserveNotMet + item.activeLotsReserveNotMet,
    closedLots: acc.closedLots + item.closedLots,
    closedLotsReserveMet: acc.closedLotsReserveMet + item.closedLotsReserveMet,
    closedLotsReserveNotMet: acc.closedLotsReserveNotMet + item.closedLotsReserveNotMet,
  }));
};

interface TotalMaxBidsSummaryProps {
  auctionDetails: AuctionDetails;
}

const TotalMaxBidsSummary: React.FC<TotalMaxBidsSummaryProps> = ({ auctionDetails }) => {
  const { t } = useDashboardTranslation();

  if (!auctionDetails.totalMaxBids) return null;

  const asCurrency = formatCurrency(auctionDetails.currencyCode);
  const isListedOnMultiplePlatforms = auctionDetails.bidders.length > 1;
  const rows = isListedOnMultiplePlatforms
    ? [...auctionDetails.totalMaxBids, createTotalsRow(auctionDetails.totalMaxBids, t('auctionDetails.totals'))]
    : auctionDetails.totalMaxBids;

  return (
    <Table striped responsive size="sm" className="atg-table">
      <thead>
        <tr>
          {isListedOnMultiplePlatforms && <th>{t('auctionDetails.platform')}</th>}
          <th>{t('auctionDetails.allLots')}</th>
          <th>{t('auctionDetails.activeLots')}</th>
          <th>{t('auctionDetails.closedLots')}</th>
        </tr>
      </thead>
      <tbody>
        {rows.map(item => (
          <tr key={item.platform} data-testid={item.platform}>
            {isListedOnMultiplePlatforms && <td>{item.platform}</td>}
            <td data-testid="all-lots">
              {auctionDetails.showReserves ? (
                <ReserveBreakdown
                  value={asCurrency(item.allLots)}
                  metValue={asCurrency(item.allLotsReserveMet)}
                  notMetValue={asCurrency(item.allLotsReserveNotMet)}
                />
              ) : (
                asCurrency(item.allLots)
              )}
            </td>
            <td data-testid="active-lots">
              {auctionDetails.showReserves ? (
                <ReserveBreakdown
                  value={asCurrency(item.activeLots)}
                  metValue={asCurrency(item.activeLotsReserveMet)}
                  notMetValue={asCurrency(item.activeLotsReserveNotMet)}
                />
              ) : (
                asCurrency(item.activeLots)
              )}
            </td>
            <td data-testid="closed-lots">
              {auctionDetails.showReserves ? (
                <ReserveBreakdown
                  value={asCurrency(item.closedLots)}
                  metValue={asCurrency(item.closedLotsReserveMet)}
                  notMetValue={asCurrency(item.closedLotsReserveNotMet)}
                />
              ) : (
                asCurrency(item.closedLots)
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default TotalMaxBidsSummary;
