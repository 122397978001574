import React from 'react';
import { useTranslation } from 'react-i18next';

import './SyncInfo.scss';

const SyncInfo: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="sync-info">
      <span className="icon icon-sync" />
      <h5>{t('syncInfo')}</h5>
    </div>
  );
};

export default SyncInfo;
