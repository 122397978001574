import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import SimpleBar from 'simplebar-react';
import classNames from 'classnames';

import useDashboardTranslation from 'hooks/useDashboardTranslation';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';

import './PendingBiddersModal.scss';
import usePendingBidders from './hooks/usePendingBidders';
import PendingBiddersTable from './PendingBiddersTable';
import BidderInfo from '../AuctionLots/SidePanel/BidderInfo';

interface PendingBiddersModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const PendingBiddersModal: React.FC<PendingBiddersModalProps> = ({ isOpen, onClose }) => {
  const { t, tCommon } = useDashboardTranslation();
  const { data: pendingBidders, isLoading } = usePendingBidders();
  const [currentBidderId, setCurrentBidderId] = useState<string>();

  return (
    <Modal className="pending-bidders-modal" size="lg" isOpen={isOpen} toggle={onClose}>
      <ModalHeader toggle={onClose}>{t('auctionDetails.pendingBiddersModal.title')}</ModalHeader>
      <ModalBody>
        {isLoading ? (
          <div className="pending-bidders-modal-loading">
            <LoadingIndicator />
          </div>
        ) : (
          <div className="pending-bidders-modal-content">
            {pendingBidders ? (
              <SimpleBar>
                <PendingBiddersTable
                  bidders={pendingBidders}
                  activeBidderId={currentBidderId}
                  onBidderDetailsClick={(bidderId: string) =>
                    setCurrentBidderId(id => (id === bidderId ? undefined : bidderId))
                  }
                />
              </SimpleBar>
            ) : (
              <p className="text-center">{t('auctionDetails.pendingBiddersModal.noPendingBidders')}</p>
            )}
            <div className="divider" />
            <div className={classNames('bidder-panel', { 'no-data': !currentBidderId })}>
              {currentBidderId ? <BidderInfo bidderId={currentBidderId} /> : <span className="icon-person" />}
            </div>
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>
          {tCommon('close')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default PendingBiddersModal;
