import React from 'react';
import { useMeasure } from 'react-use';

import './Dashboard.scss';

import AuctionDetails from './AuctionDetails/AuctionDetails';
import AuctionLots from './AuctionLots/AuctionLots';

const Dashboard: React.FC = () => {
  const [auctionDetailsRef, { height }] = useMeasure<HTMLDivElement>();

  return (
    <div className="dashboard">
      <AuctionDetails ref={auctionDetailsRef} />
      <AuctionLots height={`calc(100% - ${height}px)`} />
    </div>
  );
};

export default Dashboard;
