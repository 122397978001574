import { useQuery } from 'react-query';

import { useAppContext } from 'contexts/AppContextProvider';
import { fetchPendingBidders } from 'api';

const usePendingBidders = () => {
  const { auctionDetails } = useAppContext();
  return useQuery(['pendingBidders', auctionDetails.ref], async () => {
    const { data } = await fetchPendingBidders(auctionDetails.ref);

    return data;
  });
};

export default usePendingBidders;
