import React from 'react';
import classNames from 'classnames';

import { LotSortOption } from '../hooks/useLots';

interface SortButtonProps {
  ascSortOption: LotSortOption;
  descSortOption: LotSortOption;
  defaultSortOption: LotSortOption;
  activeSortBy: LotSortOption;
  onClick: (sortOption: LotSortOption) => void;
}

const SortButton: React.FC<SortButtonProps> = ({
  ascSortOption,
  descSortOption,
  defaultSortOption,
  activeSortBy,
  onClick,
}) => {
  const otherSortOption = defaultSortOption === ascSortOption ? descSortOption : ascSortOption;

  return (
    <button
      type="button"
      data-testid="sort-button"
      onClick={() => onClick(activeSortBy === defaultSortOption ? otherSortOption : defaultSortOption)}
    >
      <span className={classNames('icon icon-sort_asc', { active: activeSortBy === ascSortOption })} />
      <span className={classNames('icon icon-sort_desc', { active: activeSortBy === descSortOption })} />
    </button>
  );
};

export default SortButton;
